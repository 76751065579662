//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  @include media-up(lg){
    min-width: 1200px;
    width: 100%;
  }
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-body;
  font-size: 14px;
  line-height: 1.6;
  color: $color-black;
  text-align: left;
  font-weight: 300;


}

::selection {
    background: $color-main;
    color: $color-white;
    text-shadow: none;
}

.sp-show {
  display: inherit;
  @include media-up(lg){
    display: none;
  }
}

.pc-show {
  display: none;
  @include media-up(lg){
    display: inherit;
  }
}


// pager

.tablenav {
  margin-top: 30px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;

  @include media-up(lg){
    margin-top: 80px;
    margin-bottom: 150px;
  }

  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-main;
    padding: 10px;
    margin: 0 5px;
    min-width: 40px;
    color: $color-main;

    @include media-up(lg){
      font-size: 18px;
    }

    &.current {
      background-color: $color-main;
      color: $color-white;
    }
  }

  a {
    text-decoration: none;
    color: $color-main;

    &:hover {
      color:$color-white;
      background-color: $color-main;
      transition: all .2s ease;
    }
  }


}


