#lesson {
  .lesson-header {
    background-image: url(./assets/image/lesson/lesson-header_sp.png);
    @include media-up(lg){
      background-image: url(./assets/image/lesson/lesson-header_pc.png);
    }
  }

  .lesson-contents {
    margin-top: 75px;

    @include media-up(lg){
      margin-top: 140px;
    }
  }

  .lesson-about {
    margin-top: 65px;
    padding: 0 20px;

    @include media-up(lg){
      margin-top: 120px;
      padding: 0;
    }

    h2 {
      font-size: 25px;
      text-align: center;

      @include media-up(lg){
        font-size: 35px;
      }
    }

    p {
      margin-top: 25px;
      font-size: 16px;
      line-height: 1.9;

      @include media-up(lg){
        text-align: center;
      }

      + h2 {
        margin-top: 40px;

        @include media-up(lg){
          margin-top: 100px;
        }
      }
    }
  }

  .lesson-flow {
    margin-top: 60px;
    padding: 0 20px;

    @include media-up(lg){
      margin-top: 125px;
      padding: 0;
    }

    .lesson-flow__list {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      @include media-up(lg){
        padding: 0;
        margin-top: 70px;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -30px;
      }

      li {
        background-color: #E6F4F1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
        margin-bottom: 30px;

        @include media-up(lg){
          width: calc(33.333% - 30px);
          margin-left: 30px;
        }

        dl {
          dt {
            text-align: center;
            img {
              height: 120px;
              width: auto;
            }
          }
          dd {
            text-align: center;
            margin-top: 10px;

            h3 {
              font-size: 24px;
              font-weight: 500;
              color: $color-main;
            }

            p {
              margin-top: 10px;
              text-align: center;
              font-weight: 500;

              a {
                color: $color-main;
              }
            }
          }
        }
      }
    }
  }

  .lesson-access {
    margin-top: 100px;

    @include media-up(lg) {
      margin-top: 100px;
    }

    .lesson-access-body {
      @include media-up(lg){
        display: flex;
        justify-content: space-between;
      }
    }

    .lesson-access__title {
      margin-left: 20px;

      @include media-up(lg) {
        position: relative;
        margin-left: 0px;
        z-index: 80;
        width: calc(100% - 640px);
        // padding-left: 140px;
      }

      h2 {
        img {
          width: 100%;
          max-width: 180px;
          height: auto;

          @include media-up(lg) {
            max-width: 250px;
            margin-left: 0px;
          }
        }
      }

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -30px;

        @include media-up(lg) {
          font-size: 35px;
          margin-top: -50px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px;
        }
      }

      .access-logo {
        margin-top: 30px;
        text-align: center;
      }

      .access-info {
        margin-top: 20px;
        text-align: center;
      }

      .access-sns {
        margin-top: 20px;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            margin: 0 10px;

            a {
              opacity: 1;
              transition: all .2s ease;
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }

    .lesson-access__map {
      margin-top: 40px;

      @include media-up(lg){
        width: 600px;
        margin-top: 0;
      }
    }

  }

  .lesson-parking {
    margin-top: 70px;
    padding: 0 20px;

    @include media-up(lg){
      margin-top: 120px;
      padding: 0 20px;
    }

    dl {
      display: flex;
      flex-direction: column;

      @include media-up(lg){
        flex-direction: row;
      }

      dt {
        text-align: center;

        @include media-up(lg){
          width: 540px;
        }
        h2 {
          font-size: 30px;

          @include media-up(lg){
            font-size: 35px;
          }
        }

        p {
          font-size: 14px;
          margin-top: 20px;
        }
      }

      dd {
        padding: 20px;
        text-align: center;

        @include media-up(lg){
          width:  300px;
        }

        img {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  .lesson-procedure {
    margin-top: 90px;
    padding: 0 20px;

    @include media-up(lg){
      margin-top: 240px;
      padding: 0;
    }

    .lesson-procedure__list {
      display: flex;
      flex-direction: column;
      margin-top: 50px;

      @include media-up(lg){
        flex-direction: row;
        flex-wrap: wrap;
      }

      li {
        + li {
          margin-top: 50px;

          @include media-up(lg){
            margin-top: 80px;
          }
        }
      }

      dl {
        display: flex;
        flex-direction: column;

        @include media-up(lg){
          flex-direction: row;
        }

        dt {
          @include media-up(lg){
            width: calc(100% - 330px);
            padding-right: 30px;
          }
          span {
            margin-right: 15px;
            img {
              height: 70px;
              width: auto;

              @include media-up(lg){
                height: 90px;
              }
            }
          }

          h3 {
            font-size: 25px;
            line-height: 1.3;
            display: flex;
            align-items: center;


            @include media-up(lg){
              font-size: 35px;
            }
          }

          p {
            font-size: 16px;
            margin-top: 10px;
          }
        }

        dd {
          margin-top: 10px;

          @include media-up(lg){
            margin-top: 0;
            width: 330px;
          }
          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .lesson-application {
    margin-top: 100px;

    @include media-up(lg){
      margin-top: 150px;
    }
  }
}
