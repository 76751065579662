#index {
  min-height: 100vh;

  .index-cover {
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .index-cover__pc {
    background-image: url(./assets/image/index-cover-img_pc.png);
    background-size: cover;
    background-position: left top;
    height: 678px;
  }

  .index-cover-copy {
    margin-left: 50%;
    width: 50%;

    .index-cover-copy__01 {
      width: 640px;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;

      img {
        width: 340px;
      }
    }

    .index-cover-copy__02 {
      width: 640px;
    }
  }

  .index-news {
    background-color: $color-main;
  }

  .index-news-innner {
    display: flex;
    padding: 10px;
    align-items: center;

    @include media-up(md) {
      padding: 20px 0;
      width: 100%;
    }

    .index-news__title {
      color: $color-main;
      font-size: 16px;
      background-color: $color-white;
      padding: 5px 10px;
      line-height: 1;

      @include media-up(lg) {
        font-size: 20px;
        padding: 10px 20px;
        width: 150px;
        text-align: center;
      }
    }

    .index-news__post {
      margin-left: 20px;

      @include media-up(lg) {
        margin-left: 40px;
        width: calc(100% - 200px);
      }

      a {
        color: $color-white;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-up(lg) {
          width: 100%;

          &:hover {
            dd {
              text-decoration: underline;
            }
          }
        }

        &::after {
          content: '';
          width: 10px;
          height: 10px;
          background-image: url(./assets/image/cursor-right.svg);
          background-repeat: no-repeat;
          background-size: auto 100%;
          display: block;
          margin-left: 5px;

          @include media-up(lg) {
            width: 10px;
            height: 15px;
          }
        }
      }

      dl {
        display: flex;
        line-height: 1;
        align-items: center;

        dt {
          margin-right: 10px;
          font-size: 10px;
          line-height: 1;
          padding-top: 5px;

          @include media-up(lg) {
            font-size: 14px;
            padding: 0;
            margin-right: 20px;
          }
        }

        dd {
          font-size: 16px;
          line-height: 1;

          @include media-up(lg) {
            font-size: 20px;
          }
        }
      }
    }
  }

  .index-flow {
    margin-top: 20px;

    .index-flow-inner {}

    .index-flow__title {
      text-align: right;
      margin-right: 20px;

      @include media-up(lg) {
        position: relative;
        margin-right: 80px;
        z-index: 80;
      }

      h2 {
        img {
          width: 100%;
          max-width: 268px;
          height: auto;

          @include media-up(lg) {
            max-width: 450px;
          }
        }

      }
    }

    .index-flow-list {
      display: flex;
      flex-direction: column;
      margin-top: -30px;

      @include media-up(lg) {
        margin-top: -50px;
      }
    }

    .index-flow-block {

      +.index-flow-block {
        margin-top: 50px;

        @include media-up(lg) {
          margin-top: 90px;
        }
      }

      @include media-up(lg) {
        &:nth-of-type(even) {
          dl {
            flex-direction: row;

            dd {
              width: 500px;
              height: 320px;
              position: absolute;
              left: auto !important;
              right: 100px;
              top: 0;
              bottom: 0;
              margin: auto;

              .flow-block__number {
                right: 10px;
                left: auto !important;
              }
            }
          }
        }
      }

      dl {

        @include media-up(lg) {
          display: flex;
          flex-direction: row-reverse;
          position: relative;
        }

        dt {

          @include media-up(lg) {
            width: 800px;
          }

          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
          }
        }

        dd {

          @include media-up(lg) {
            width: 500px;
            height: 320px;
            position: absolute;
            left: 100px;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          background-color: rgba(#E4F3F0, 0.9);
          margin: -20px 10px 0;
          position: relative;
          z-index: 2;
          box-shadow: 0px 0px 10px rgba(#000, 0.3);
          padding: 20px;

          .flow-block__number {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 50px;
            height: 50px;

            @include media-up(lg) {
              bottom: auto;
              top: -80px;
              right: auto;
              left: 10px;
              width: 100px;
              height: 100px;
            }

            img {
              width: 100%;
              height: auto;
            }
          }

          h3 {
            font-size: 25px;
            color: #707070;
            font-weight: 300;

            @include media-up(lg) {
              font-size: 35px;
            }
          }

          p {
            font-size: 16px;
            color: #707070;
            margin-top: 10px;
            padding-bottom: 30px;


            @include media-up(lg) {
              margin-top: 20px;
              padding-bottom: 0;
            }
          }
        }

      }
    }
  }

  .index-trouble {
    margin-top: 60px;

    @include media-up(lg) {
      margin-top: 100px;
    }

    .index-trouble__title {
      margin-left: 20px;

      @include media-up(lg) {
        position: relative;
        margin-left: 100px;
        z-index: 80;
      }

      h2 {
        img {
          width: 100%;
          max-width: 200px;
          height: auto;

          @include media-up(lg) {
            max-width: 270px;
          }
        }

      }
    }

    .index-trouble__copy {
      margin-top: -20px;

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;

        @include media-up(lg) {
          font-size: 35px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px;
        }
      }
    }

    .index-trouble__list {
      margin-top: 20px;
      padding: 0 10px;

      @include media-up(lg) {
        padding: 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 25%;
          margin-top: 20px;

          @include media-up(lg) {
            margin-top: 40px;
          }

          dl {
            dt {
              padding: 10px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;

              @include media-up(lg) {
                height: 100px;
              }

              img {
                width: auto;
                height: 100%;
              }
            }

            dd {
              text-align: center;
              font-size: 16px;
              margin-top: 10px;
              font-weight: 300;
            }
          }
        }
      }
    }

    .index-trouble__link {
      margin-top: 50px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .index-trial {
    margin-top: 60px;

    @include media-up(lg) {
      margin-top: 100px;
    }

    .index-trial__title {
      margin-left: 20px;

      @include media-up(lg) {
        position: relative;
        margin-left: 100px;
        z-index: 80;
      }

      h2 {
        img {
          width: 100%;
          max-width: 110px;
          height: auto;

          @include media-up(lg) {
            max-width: 160px;
          }
        }

      }
    }

    .index-trial__copy {
      margin-top: -20px;

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;

        @include media-up(lg) {
          font-size: 35px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px;
        }
      }

      p {
        margin-top: 20px;
        padding: 0 10px;

        @include media-up(lg) {
          margin-top: 40px;
          text-align: center;
          line-height: 2;
          font-size: 15px;
          padding: 0;
        }
      }
    }

    .index-trial__figure {
      margin-top: 20px;
      padding: 10px;

      @include media-up(lg) {
        margin-top: 40px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }


    .index-trial__link {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;

      @include media-up(lg) {
        margin-top: 60px;
      }
    }
  }

  .index-campaign {
    margin-top: 80px;
    padding: 10px;

    @include media-up(lg) {
      margin-top: 100px;
      padding: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .index-application {
    margin-top: 60px;

    @include media-up(lg) {
      margin-top: 100px;
    }
  }

  .index-trainer {
    margin-top: 60px;

    @include media-up(lg) {
      margin-top: 150px;
    }

    img {
      width: 100%;
      height: auto;
    }

    .index-trainer-cover {
      dl {
        display: flex;
        flex-direction: column;

        @include media-up(lg) {
          flex-direction: row-reverse;
        }

        dt {
          padding: 0 40px;

          @include media-up(lg) {
            padding: 0;
            width: 640px;
          }
        }

        dd {
          @include media-up(lg) {
            width: 450px;
            margin-right: 50px;
          }
        }
      }

      .index-trainer__title {
        position: relative;

        h2 {
          position: relative;
          left: -10px;
          width: 180px;
          top: 0;

          @include media-up(lg) {
            width: 250px;
          }
        }

        p {
          text-align: center;
          font-size: 25px;
          margin-top: -35px;

          @include media-up(lg) {
            font-size: 35px;
            margin-top: -50px;
          }
        }
      }

      .index-trainer__copy {
        padding: 20px;

        @include media-up(lg) {
          padding: 0;
          margin-top: 30px;
        }

        h3 {
          font-size: 25px;

          @include media-up(lg) {
            font-size: 35px;
          }
        }

        p {
          margin-top: 15px;
          font-size: 16px;

          &.name {
            text-align: right;
          }
        }
      }
    }

    .index-trainer-profile {
      margin-top: 40px;

      @include media-up(lg) {
        margin-top: 100px;
      }

      dl {
        @include media-up(lg) {
          display: flex;

        }

        dt {
          padding: 0 40px;

          @include media-up(lg) {
            padding: 0;
            width: 640px;
          }
        }

        dd {
          margin-top: 40px;
          padding: 0 20px;

          @include media-up(lg) {
            margin-top: 0;
            padding: 0;
            width: 450px;
            margin-left: 50px;
          }

          h3 {
            color: $color-main;
            border-bottom: 1px solid $color-main;
            padding-bottom: 5px;
            font-size: 25px;
          }

          p {
            margin-top: 10px;
            font-size: 16px;

            @include media-up(lg) {
              margin-top: 20px;
            }

            +h3 {
              margin-top: 30px;

              @include media-up(lg) {
                margin-top: 40px;
              }
            }
          }
        }
      }
    }
  }

  .index-menu {
    margin-top: 100px;

    @include media-up(lg) {
      margin-top: 100px;
    }

    .index-menu__title {
      margin-left: 20px;

      @include media-up(lg) {
        position: relative;
        margin-left: 0px;
        z-index: 80;
      }

      h2 {
        img {
          width: 100%;
          max-width: 130px;
          height: auto;

          @include media-up(lg) {
            max-width: 200px;
            margin-left: 200px;
          }
        }
      }

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -30px;

        @include media-up(lg) {
          font-size: 35px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px;
        }
      }
    }


    .index-menu__list {
      padding: 20px;
      margin-top: 20px;

      ul {
        display: flex;
        flex-direction: column;

        @include media-up(lg) {
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: -30px;
        }

        li {
          background-color: rgba(#E4F3F0, 0.9);
          padding: 20px;

          @include media-up(lg) {
            width: calc(50% - 30px);
            margin-left: 30px;
            margin-bottom: 30px;
          }

          +li {
            margin-top: 20px;

            @include media-up(lg) {
              margin-top: 0;
            }
          }

          dl {
            dt {
              text-align: center;
              color: $color-main;
              font-size: 20px;

              @include media-up(lg) {
                font-size: 25px;
              }
            }

            dd {
              margin-top: 20px;
              font-size: 16px;

            }
          }
        }
      }
    }

    .index-menu__link {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;

      @include media-up(lg) {
        margin-top: 20px;
      }
    }
  }

  .index-access {
    padding-top:130px;
    margin-top: -100px;

    @include media-up(lg) {
      margin-top: 100px;
    }

    .index-access-body {
      @include media-up(lg){
        display: flex;
      }
    }

    .index-access__title {
      margin-left: 20px;

      @include media-up(lg) {
        position: relative;
        margin-left: 0px;
        z-index: 80;
        width: calc(100% - 640px);
        padding-left: 140px;
      }

      h2 {
        img {
          width: 100%;
          max-width: 180px;
          height: auto;

          @include media-up(lg) {
            max-width: 250px;
            margin-left: 0px;
          }
        }
      }

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -30px;

        @include media-up(lg) {
          font-size: 35px;
          margin-top: -50px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px;
        }
      }

      .access-logo {
        margin-top: 30px;
        text-align: center;
      }

      .access-info {
        margin-top: 20px;
        text-align: center;
      }

      .access-sns {
        margin-top: 20px;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            margin: 0 10px;

            a {
              opacity: 1;
              transition: all .2s ease;
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }

    .index-access__map {
      margin-top: 40px;

      @include media-up(lg){
        width: 640px;
        margin-top: 0;
      }
    }

  }

  .index-blog {
    margin-top: 100px;

    @include media-up(lg) {
      margin-top: 100px;
    }

    .index-blog__title {
      margin-left: 0px;

      @include media-up(lg) {
        position: relative;
        margin-left: 0px;
        z-index: 80;
      }

      h2 {
        img {
          width: 100%;
          max-width: 110px;
          height: auto;
          margin-left: 20%;

          @include media-up(lg) {
            max-width: 150px;
            margin-left: 300px;
          }
        }
      }

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -20px;

        @include media-up(lg) {
          font-size: 35px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px;
        }
      }
    }

    .index-blog__list {
      margin-top: 40px;
      padding: 0 10px;

      ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;

        @include media-up(lg){
          margin-left: -20px;
        }

        .index-blog__item {
          width: calc(50% - 10px);
          margin-left: 10px;
          margin-bottom: 20px;

          @include media-up(lg){
            width: calc(25% - 20px);
            margin-left: 20px;
          }

          img {
            width: 100%;
            height: auto;
          }

          a {
            text-decoration: none;
            color: $color-main;

            @include media-up(lg){
              display: block;
              &:hover {
                opacity: 0.6;
                transition: all .2s ease;
              }
            }
          }

          .date {
            font-size: 10px;
            color: $color-black;

            @include media-up(lg){
              font-size: 14px;
            }
          }

          .title {
            font-size: 12px;

            @include media-up(lg){
              font-size: 16px;
            }
          }
        }
      }
    }





    .index-blog__link {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;

      @include media-up(lg) {
        margin-top: 20px;
      }
    }
  }

  .index-contact {
    margin-top: 60px;

    @include media-up(lg){
      margin-top: 150px;
    }
  }
}
