#worries {
  .worries-header {
    background-image: url(./assets/image/worries/worries-header_sp.png);

    @include media-up(lg){
      background-image: url(./assets/image/worries/worries-header_pc.png);
    }
  }

  .worries-list {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;

    @include media-up(lg){
      margin-top: 100px;
      margin-left: -20px;
      padding: 0;
    }

    .entry {
      width: calc(50% - 10px);
      margin-left: 10px;
      margin-bottom: 10px;
      background-color: $color-main;
      min-height: 130px;

      @include media-up(lg){
        width: calc(25% - 20px);
        margin-left: 20px;
        margin-bottom: 20px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        color: $color-white;
        text-decoration: none;
        font-size: 16px;
        text-align: center;
        padding: 20px;
        height: 100%;


        @include media-up(lg){
          font-size: 20px;
          padding: 40px;

          &:hover {
            opacity: 0.5;
            transition: all .2s ease;
          }
        }
      }
    }
  }


}
