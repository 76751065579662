#blog {

  .blog-header {
    background-image: url(./assets/image/blog/blog-header_sp.png);
    @include media-up(lg){
      background-image: url(./assets/image/blog/blog-header_pc.png);
    }
  }

  .blog-list {
    margin-top: 70px;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;

    @include media-up(lg){
      margin-left: -20px;
      padding: 0;
    }

    .entry {
      width: calc(50% - 10px);
      margin-left: 10px;
      margin-bottom: 20px;

      @include media-up(md){
        width: calc(33.333% - 10px);
      }

      @include media-up(lg){
        width: calc(25% - 20px);
        margin-left: 20px;
      }

      a {
        display: block;
        text-decoration: none;
        color: $color-main;

        @include media-up(lg){
          &:hover {
            opacity: 0.5;
            transition: all .2s ease;
          }
        }
      }
      dl {
        dt {
          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
          }
        }
        dd {
          margin-top: 10px;
          .entry-date {
            color: $color-black;
          }
          .entry-title {
            font-size: 14px;

            @include media-up(lg){
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}


.side-nav {

  padding: 60px 40px 0;

	@include media-up(lg){

    display: flex;
    padding: 0;
    margin-top: 80px;
    margin-bottom: 90px;
    justify-content: space-between;
  }

	.side-nav-col {
    margin-bottom: 20px;
    border: 1px solid $color-main;

    @include media-up(lg) {
      width: 30%;
    }

		form {
			display: block;
			width:100%;
		}

		select {
			background-color: $color-white;
			display: block;
			width:100%;
			padding: 10px 20px;
      position: relative;
      border: none;
      -moz-appearance: none;    // Firefox 40+
      -webkit-appearance: none; // Chrome 45+
      font-size: 14px;
      font-weight: 300;
      border-radius: 4px;
      color: $color-black;
		}
		label{
			position: relative;
			display: block;

			&:after{
				content:"\f078";
				font-family: FontAwesome;
				position: absolute;
				top: 10px;
				right: 20px;
        font-size: 12px;
        color: $color-gray;
				display: block;
				pointer-events: none;
			}
		}
	}
}
