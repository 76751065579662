// コンテナ
.c-wrapper {
  padding-top: 60px;

  @include media-up(lg){
    padding-top: 80px;
  }
}

.c-pageheader {
  background-color: #E6F4F1;
  // background-image: url(./assets/image/worries/worries-header_sp.png);
  background-size: cover;
  height: 105vw;
  position: relative;

  @include media-up(md){
    height: 70vw;
  }

  @include media-up(lg){
    height: 400px;
    // background-image: url(./assets/image/worries/worries-header_pc.png);
    background-position: center right;
  }

  h1, .c-title {
    background-image: url(./assets/image/page-header-bg.png);
    background-size: 100% 100%;
    width: 85vw;
    font-weight: 300;
    color: $color-white;
    position: absolute;
    left:0;
    bottom: 8vw;
    font-size: 6vw;
    padding: 20px 30px;
    text-align: center;

    @include media-up(lg){
      top:50%;
      transform: translateY(-50%);
      bottom:auto;
      margin: auto;
      display: block;
      width: 50%;
      font-size: 36px;
      padding: 20px;
      // padding-left: 7%;
    }
  }
}

.c-container {
  width: 100%;
  max-width: 1000px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;

  @include media-up(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }

}

.c-container--sm {
  width: 100%;
  max-width: 800px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;

  @include media-up(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.c-container--lg {
  width: 100%;
  max-width: 1280px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;

  @include media-up(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.c-linebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-main;
  color: $color-main;
  padding: 20px;
  font-weight: 300;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  &::before{
    position: absolute;
    content: '';
    right: 15px;
    width: 10px;
    height: 10px;
    border-top: 1px solid $color-main;
    border-right: 1px solid $color-main;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover {
    transition: all .2s ease;
    background-color: $color-main;
    color: $color-white;

    &::before{
      border-top: 1px solid $color-white;
      border-right: 1px solid $color-white;
    }
  }
}

.c-fillbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-main;
  color: $color-white;
  padding: 20px;
  box-shadow: 0 5px 0 #0C4471;
  font-weight: 300;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid $color-main;

  &::before{
    position: absolute;
    content: '';
    right: 15px;
    width: 10px;
    height: 10px;
    border-top: 1px solid $color-white;
    border-right: 1px solid $color-white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover {
    transition: all .2s ease;
    background-color: $color-white;
    color: $color-main;
    border: 1px solid $color-main;

    &::before{
      border-top: 1px solid $color-main;
      border-right: 1px solid $color-main;
    }
  }
}

.c-sectiontitle {
  color: $color-main;
  font-size: 25px;
  position: relative;

  @include media-up(lg){
    font-size: 35px;
  }

  &::after {
    content:'';
    width: 100px;
    height: 1px;
    display: block;
    background-color: $color-main;
    margin-top: 15px;
  }

}

// entyr
.c-entry {
  margin-top: 60px;
  padding: 0 20px;

  @include media-up(lg){
    margin-top: 100px;
    padding: 0;
  }

  .c-entry-title {
    font-size: 25px;

    @include media-up(lg){
      font-size: 35px;
    }
  }

  .c-entry-date {
    margin-top: 10px;
    font-size: 16px;

    @include media-up(lg){
      margin-top: 25px;
    }
  }

  .c-entry-contents {
    margin-top: 25px;

    p,h2,h3,h4 {
      margin-top: 15px;
    }

    p {
      font-size: 16px;
      line-height: 1.8;
    }

    h1 {
      font-size: 25px;

      @include media-up(md){
        font-size: 35px;
      }
    }

    h2 {
      font-size: 24px;
      @include media-up(md){
        font-size: 26px;
      }
    }

    h3 {
      font-size: 20px;
      @include media-up(md){
        font-size: 22px;
      }
    }

    h4 {
      font-size: 18px;
    }

    img {
      max-width: 100%;
      width: auto;
      margin-top: 30px;
      margin-bottom: 15px;
      vertical-align: middle;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;

      h1,h2,h3,h4 {
        display: inline !important;
      }

      li {
        + li{
          margin-top: 15px;
        }
      }
    }

    ol {
      list-style-type: decimal;
      list-style-position: inside;

      h1,h2,h3,h4 {
        display: inline !important;
      }

      li {
        + li{
          margin-top: 15px;
        }
      }
    }

  }
}

.c-entry-nav {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-up(lg){
    margin-top: 150px;
    margin-bottom: 180px;
  }

  .c-entry-nav__btn {
    border: 1px solid $color-main;
    margin: 0 5px;

    &.is--large {
      min-width: 200px;
      text-align: center;
    }

    a {
      padding: 20px;
      display: block;
      font-size: 16px;
      text-decoration: none;
      color: $color-main;
      line-height: 1;

      @include media-up(lg){
        font-size: 18px;

        &:hover {
          background-color: $color-main;
          color: $color-white;
          transition: all .2s ease;
        }
      }
    }
  }
}











#p-campaign {
  img {
    width: 100%;
    height: auto;
  }
}

#p-application {
  background-image: url(./assets/image/application-bg_sp.png);
  background-size: cover;
  padding: 40px 20px;

  @include media-up(lg){
    background-image: url(./assets/image/application-bg_pc.png);
    padding: 80px;

  }

  h2 {
    text-align: center;
    font-size: 25px;

    @include media-up(lg){
      font-size: 35px;
    }
  }
  p {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;

    @include media-up(lg){
      margin-top: 30px;

      br {
        display: none;
      }
    }
  }

  .p-application__link {
    margin-top: 30px;

    @include media-up(lg){
      margin-top: 50px;
    }
  }
}


#p-contact {
  background-image: url(./assets/image/contact-bg_sp.png);
  background-size: cover;
  padding: 40px 20px;

  color: $color-white;

  @include media-up(lg){
    background-image: url(./assets/image/contact-bg_pc.png);
    padding: 80px;

  }
  .p-contact-title {
    margin-left: 0px;

    @include media-up(lg) {
      position: relative;
      margin-left: 0px;
      z-index: 80;
    }

    h2 {
      img {
        width: 100%;
        max-width: 190px;
        height: auto;
        margin-left: 5%;

        @include media-up(lg) {
          max-width: 275px;
          margin-left:200px;
        }
      }
    }

    h3 {
      text-align: center;
      font-size: 25px;
      font-weight: 300;
      margin-top: -30px;

      @include media-up(lg) {
        font-size: 35px;
      }

      span {
        display: block;
        font-size: 16px;
        font-weight: 300;
        padding-bottom: 10px;
      }
    }

    p {
      margin-top: 30px;
      font-size: 16px;
      text-align: center;
    }
  }


  .p-contact-link {
    margin-top: 30px;

    @include media-up(lg){
      margin-top: 50px;
    }
  }
}
