#footer {
  background-color: #E6F4F1;

  .footer-inner {
    padding: 30px 20px 60px;

    @include media-up(lg){
      padding: 60px 40px;
    }
  }

  .footer-nav {

    @include media-up(lg){
      width: 640px;
      margin: 0 auto;
    }
    ul {
      display: flex;
      flex-wrap:wrap;
      justify-content: center;

      li {
        padding: 5px;

        @include media-up(lg){
          padding: 20px;
        }

        a {
          color: $color-black;
          text-decoration: none;
          font-size: 16px;
        }
      }
    }
  }

  .footer-logo {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-up(lg){
      margin-top: 40px;
    }
  }

  .footer-cp {
    margin-top: 20px;
    font-size: 10px;
    color: $color-sub;
    text-align: center;

    @include media-up(lg){
      margin-top: 40px;
      font-size: 12px;
    }
  }
}
