#contact {

  .contact-header {
    background-image: url(./assets/image/contact/contact-header_sp.png);
    @include media-up(lg){
      background-image: url(./assets/image/contact/contact-header_pc.png);
    }
  }

  .contact-copy {
    margin-top: 60px;
    padding: 0 20px;

    @include media-up(lg){
      margin-top: 100px;
      padding: 0;
    }

    h2 {
      font-size: 25px;
      text-align: center;

      @include media-up(lg){
        font-size: 35px;
      }
    }

    p {
      margin-top: 40px;
      font-size: 16px;

      @include media-up(lg){
        text-align: center;
        margin-top: 50px;
      }
    }

    .c-container__tel {
      margin-top: 35px;
      text-align: center;
      @include media-up(lg){
        margin-top: 65px;
      }

      span {
        display: block;
        font-size: 12px;
      }

      strong {
        font-size: 35px;
      }
    }
  }

  .contact-form {
    padding: 0 20px;
    margin-top: 70px;

    .c-sectiontitle {
      font-size: 23px;

      @include media-up(lg){
        margin-top: 130px;
        font-size: 35px;
      }
    }

    .contact-form__copy {
      margin-top: 30px;
      font-size: 16px;
    }

    .contact-form-body{
      margin-top: 40px;

      max-width: 600px;
      margin: 40px auto 0;

      @include media-up(lg){
        max-width: 100%;
        margin: 80px 0;
      }

      ::placeholder {
        color: #ccc;
      }

      textarea {
        padding: 5px;
        background-color: $color-white;
        border: 1px solid $color-main;
        width: 100%;
        font-size: 16px;
        padding: 10px;
        font-weight: 300 !important;
        border-radius: 0;
        @include media-up(lg) {
          padding: 5px;
          max-width: 500px;
        }
      }

      input {
        width: 100%;
        background-color: $color-white;
        border: 1px solid $color-main;
        padding: 10px;
        font-weight: 300 !important;
        font-size: 16px;
        border-radius: 0;
        &[type="radio"] {
          width: auto !important;
        }
        @include media-up(lg) {
          max-width: 100%;
          padding: 10px 20px;
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline: none;
        background: transparent;
        border-radius: 0;

        width: 100%;
        border: 1px solid $color-main;
        padding: 10px;
        font-weight: 300 !important;

        background-image: url(./assets/image/cursor-down.png);
        background-position: center right 10px;
        background-repeat: no-repeat;
        background-size: 10px auto;
        option {
          text-align: left;
          font-weight: 300 !important;
        }
      }

      .contact-form-item {
        dl {
          display: flex;
          flex-direction: column;

          @include media-up(lg){
            flex-direction: row;
          }

          dt {
            font-size: 16px;
            display: flex;
            justify-content: space-between;

            @include media-up(lg){
              align-items: flex-start;
              width: 200px;
            }
            span {
              background-color: $color-main;
              color: $color-white;
              font-size: 12px;
              padding: 5px 10px;
            }
          }

          dd {
            margin-top: 10px;

            @include media-up(lg){
              margin-top: 0;
              width: calc(100% - 200px);
              padding-left: 60px;
            }
          }
        }

        &.is--checkbox {
          dt {
            justify-content: flex-start;

            @include media-up(lg){
              justify-content: space-between;
            }

            span {
              margin-left: 20px;
            }
          }
        }

        .horizontal-item {
          display: block;
          margin-left: 0;
          margin-top: 10px;
          font-size: 16px;

          @include media-up(lg){
            margin-bottom: 20px;
            margin-top: 0;
          }

          .mwform-radio-field-text {
            font-weight: 300 !important;
          }

          input {
            position: absolute;
            white-space: nowrap;
            width: 1px;
            height: 1px;
            overflow: hidden;
            border: 0;
            padding: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            margin: -1px;
          }
          label {
            cursor: pointer;
            padding-left: 40px;
            position: relative;
          }


          span::before,
          span::after {
            content: "";
            display: block;
            position: absolute;
          }

          span::before {
            background-color: #fff;
            border-radius: 0%;
            border: 1px solid $color-main;
            width: 30px;
            height: 30px;
            transform: translateY(-50%);
            top: 50%;
            left: 0px;
          }

          span::after {
            border-bottom: 2px solid $color-main;
            border-left: 2px solid $color-main;
            opacity: 0;
            height: 10px;
            width: 20px;
            transform: rotate(-45deg);
            top: 2px;
            left: 5px;
          }

          input:checked + span::after {
            opacity: 1;
          }

        }

        + .contact-form-item {
          margin-top: 30px;

          @include media-up(lg){
            margin-top: 40px;
          }
        }
      }

      .contact-form-text {
        margin-top: 40px;
        font-size: 16px;

        @include media-up(lg){
          margin-top: 100px;
          font-size: 18px;
          text-decoration: underline;

          &.is--center {
            text-align: center;

          }
        }
      }

      .contact-form-item-col2 {
        margin-top: 30px;

        @include media-up(lg){
          font-size: 16px;
        }

        .item-col2 {
          display: flex;
          justify-content: space-between;

          .item-col2__m {
            width: 60px;

            @include media-up(lg){
              width: 200px;
            }
          }

          .item-col2__s {
            width: calc(100% - 80px);

            @include media-up(lg){
              width: calc(100% - 200px);
              padding-left:60px;
            }
          }

          .item-col2-sub {
            display: flex;
            align-items: center;

            + .item-col2-sub {
              margin-top: 20px;
            }

            .item-col2-sub__m {
              width: 60px;
              text-align: right;

              @include media-up(lg){
                text-align: left;
              }
            }

            .item-col2-sub__s {
              width: calc(100% - 60px);
              padding-left: 20px;

              @include media-up(lg){
                select {
                  max-width: 300px;
                }
              }

            }
          }
        }


        + .contact-form-item-col2 {
          margin-top: 50px;
        }

        &.is--textarea {
          margin-top: 60px;

          @include media-up(lg){
            margin-top: 80px;
          }
          .item-col2 {
            flex-direction: column;
            justify-content: flex-start;

            @include media-up(lg){
              flex-direction: row;
            }

            .item-col2__m {
              width: 100%;
              font-size: 16px;

              @include media-up(lg){
                width: 200px;
              }
            }
            .item-col2__s {
              width: 100%;
              font-size: 16px;
              margin-top: 25px;

              @include media-up(lg){
                width: calc(100% - 200px);
                padding-left: 60px;
                margin-top: 0;
              }

              .textarea {
                margin-top: 20px;

                @include media-up(lg){
                  width: 100%;

                  textarea {
                    max-width: 100%;
                    min-height: 300px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .contact-form__submit {
      margin: 20px auto 40px;
      text-align: center;

      @include media-up(lg){
        margin-top: 60px;
        margin-bottom: 150px;

        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
      }
      input {
          max-width: 400px;
          background-color: transparent;
          text-align: center;
          cursor: pointer;
          padding: 20px;
          margin: 10px auto;
          color: $color-main;
          transition: all 0.2s ease;
          letter-spacing: .2em;
          width: 80%;
          border: 1px solid $color-main;
          font-size: 18px;
          @include media-up(lg) {
            width: 100%;
            margin: 0px 20px;
            display: inline-block;
            padding: 20px 30px;
            font-size: 20px;
          }

          &:hover {
            background-color: $color-main;
            color: #fff;
          }

          &:first-child {
            background-color: $color-main;
            color: $color-white;

            &:hover {
              opacity: 0.7;
            }
          }
      }
    }
  }

  .mw_wp_form_preview {

    &::before {
      content: "フォーム入力確認";
      position: relative;
      top:20px;
      display: block;
      font-size: 20px;
      font-weight: 300;
      width: 100%;
      text-align: center;
      color: $color-main;

      @include media-up(lg){
        top:50px;
        font-size: 25px;
      }

    }

    .contact-form-item {
      border: 1px solid #ccc;
      padding: 10px;

      + .contact-form-item {
        margin-top: 10px !important;
      }

      dt {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;

        @include media-up(lg){
          border-bottom: 0px solid #000;
          border-right: 1px solid #ccc;
          padding-bottom: 0;
        }

        span {
          display: none;
        }
      }

      dd {
        font-size: 16px;
      }
    }

    .contact-form-item-col2 {
      border: 1px solid #ccc;
      padding: 10px;

      + .contact-form-item-col2 {
        margin-top: 10px !important;
      }

      .item-col2 > .item-col2__m {
        border-right: 1px solid #ccc;
        padding-right: 10px;
        width: 100px;
        display: flex;
        align-items: center;
      }

      &.is--textarea {
        .item-col2 {
          .item-col2__m {
            width: 100%;
            border-right: 0px solid #000;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            display: flex;
            align-items: center;

            @include media-up(lg){
              border-bottom: 0px solid #000;
              border-right: 1px solid #ccc;
              padding-bottom: 0;
            }
          }

          .item-col2__s {
            margin-top: 0 !important;

            .textarea {
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    .item-col2__s {
      p {
        display: none;
      }
    }

    .contact-form-text {
      display: none;
    }
  }

  .mw_wp_form_complete {
    margin: 0px 20px;
    margin-top: 100px;
    padding: 40px 20px;
    margin-bottom: 100px;
    background: #eee;
    @include media-up(lg) {
      padding: 20px;
      margin-top: 100px;
      margin-bottom: 20px;
      margin-bottom: 150px;
    }

    .thanks-heading {
      text-align: center;
      margin-bottom: 20px;
      font-size: 20px * 0.7;
      @include media-up(lg) {
        font-size: 35px;
      }
    }
    .thanks-txt {
        margin-bottom: 40px;
    }

    .thanks-back {
        width: 200px;
        background-color: $color-main;
        margin: 0 auto;
        transition: all 0.2s ease;

        &:hover {
            background-color: $color-main;
            opacity: 0.7;
        }

        a {
            display: block;
            text-align: center;
            padding: 20px;
            color: #fff;
            letter-spacing: 0.1em;
            text-decoration: none;
        }
    }
  }
}
