@charset "UTF-8";
/*! sanitize.css v7.0.3 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

iframe {
  border: none !important; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media screen and (min-width: 1000px) {
    html {
      min-width: 1200px;
      width: 100%; } }

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #3B4043;
  text-align: left;
  font-weight: 300; }

::selection {
  background: #0063B2;
  color: #fff;
  text-shadow: none; }

.sp-show {
  display: inherit; }
  @media screen and (min-width: 1000px) {
    .sp-show {
      display: none; } }

.pc-show {
  display: none; }
  @media screen and (min-width: 1000px) {
    .pc-show {
      display: inherit; } }

.tablenav {
  margin-top: 30px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center; }
  @media screen and (min-width: 1000px) {
    .tablenav {
      margin-top: 80px;
      margin-bottom: 150px; } }
  .tablenav .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0063B2;
    padding: 10px;
    margin: 0 5px;
    min-width: 40px;
    color: #0063B2; }
    @media screen and (min-width: 1000px) {
      .tablenav .page-numbers {
        font-size: 18px; } }
    .tablenav .page-numbers.current {
      background-color: #0063B2;
      color: #fff; }
  .tablenav a {
    text-decoration: none;
    color: #0063B2; }
    .tablenav a:hover {
      color: #fff;
      background-color: #0063B2;
      transition: all .2s ease; }

.c-wrapper {
  padding-top: 60px; }
  @media screen and (min-width: 1000px) {
    .c-wrapper {
      padding-top: 80px; } }

.c-pageheader {
  background-color: #E6F4F1;
  background-size: cover;
  height: 105vw;
  position: relative; }
  @media screen and (min-width: 768px) {
    .c-pageheader {
      height: 70vw; } }
  @media screen and (min-width: 1000px) {
    .c-pageheader {
      height: 400px;
      background-position: center right; } }
  .c-pageheader h1, .c-pageheader .c-title {
    background-image: url(./assets/image/page-header-bg.png);
    background-size: 100% 100%;
    width: 85vw;
    font-weight: 300;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 8vw;
    font-size: 6vw;
    padding: 20px 30px;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      .c-pageheader h1, .c-pageheader .c-title {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        margin: auto;
        display: block;
        width: 50%;
        font-size: 36px;
        padding: 20px; } }

.c-container {
  width: 100%;
  max-width: 1000px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 1000px) {
    .c-container {
      padding-right: 20px;
      padding-left: 20px; } }

.c-container--sm {
  width: 100%;
  max-width: 800px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 1000px) {
    .c-container--sm {
      padding-right: 20px;
      padding-left: 20px; } }

.c-container--lg {
  width: 100%;
  max-width: 1280px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 1000px) {
    .c-container--lg {
      padding-right: 20px;
      padding-left: 20px; } }

.c-linebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0063B2;
  color: #0063B2;
  padding: 20px;
  font-weight: 300;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  text-align: center; }
  .c-linebutton::before {
    position: absolute;
    content: '';
    right: 15px;
    width: 10px;
    height: 10px;
    border-top: 1px solid #0063B2;
    border-right: 1px solid #0063B2;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  .c-linebutton:hover {
    transition: all .2s ease;
    background-color: #0063B2;
    color: #fff; }
    .c-linebutton:hover::before {
      border-top: 1px solid #fff;
      border-right: 1px solid #fff; }

.c-fillbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0063B2;
  color: #fff;
  padding: 20px;
  box-shadow: 0 5px 0 #0C4471;
  font-weight: 300;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #0063B2; }
  .c-fillbutton::before {
    position: absolute;
    content: '';
    right: 15px;
    width: 10px;
    height: 10px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  .c-fillbutton:hover {
    transition: all .2s ease;
    background-color: #fff;
    color: #0063B2;
    border: 1px solid #0063B2; }
    .c-fillbutton:hover::before {
      border-top: 1px solid #0063B2;
      border-right: 1px solid #0063B2; }

.c-sectiontitle {
  color: #0063B2;
  font-size: 25px;
  position: relative; }
  @media screen and (min-width: 1000px) {
    .c-sectiontitle {
      font-size: 35px; } }
  .c-sectiontitle::after {
    content: '';
    width: 100px;
    height: 1px;
    display: block;
    background-color: #0063B2;
    margin-top: 15px; }

.c-entry {
  margin-top: 60px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    .c-entry {
      margin-top: 100px;
      padding: 0; } }
  .c-entry .c-entry-title {
    font-size: 25px; }
    @media screen and (min-width: 1000px) {
      .c-entry .c-entry-title {
        font-size: 35px; } }
  .c-entry .c-entry-date {
    margin-top: 10px;
    font-size: 16px; }
    @media screen and (min-width: 1000px) {
      .c-entry .c-entry-date {
        margin-top: 25px; } }
  .c-entry .c-entry-contents {
    margin-top: 25px; }
    .c-entry .c-entry-contents p, .c-entry .c-entry-contents h2, .c-entry .c-entry-contents h3, .c-entry .c-entry-contents h4 {
      margin-top: 15px; }
    .c-entry .c-entry-contents p {
      font-size: 16px;
      line-height: 1.8; }
    .c-entry .c-entry-contents h1 {
      font-size: 25px; }
      @media screen and (min-width: 768px) {
        .c-entry .c-entry-contents h1 {
          font-size: 35px; } }
    .c-entry .c-entry-contents h2 {
      font-size: 24px; }
      @media screen and (min-width: 768px) {
        .c-entry .c-entry-contents h2 {
          font-size: 26px; } }
    .c-entry .c-entry-contents h3 {
      font-size: 20px; }
      @media screen and (min-width: 768px) {
        .c-entry .c-entry-contents h3 {
          font-size: 22px; } }
    .c-entry .c-entry-contents h4 {
      font-size: 18px; }
    .c-entry .c-entry-contents img {
      max-width: 100%;
      width: auto;
      margin-top: 30px;
      margin-bottom: 15px;
      vertical-align: middle; }
    .c-entry .c-entry-contents ul {
      list-style-type: disc;
      list-style-position: inside; }
      .c-entry .c-entry-contents ul h1, .c-entry .c-entry-contents ul h2, .c-entry .c-entry-contents ul h3, .c-entry .c-entry-contents ul h4 {
        display: inline !important; }
      .c-entry .c-entry-contents ul li + li {
        margin-top: 15px; }
    .c-entry .c-entry-contents ol {
      list-style-type: decimal;
      list-style-position: inside; }
      .c-entry .c-entry-contents ol h1, .c-entry .c-entry-contents ol h2, .c-entry .c-entry-contents ol h3, .c-entry .c-entry-contents ol h4 {
        display: inline !important; }
      .c-entry .c-entry-contents ol li + li {
        margin-top: 15px; }

.c-entry-nav {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 1000px) {
    .c-entry-nav {
      margin-top: 150px;
      margin-bottom: 180px; } }
  .c-entry-nav .c-entry-nav__btn {
    border: 1px solid #0063B2;
    margin: 0 5px; }
    .c-entry-nav .c-entry-nav__btn.is--large {
      min-width: 200px;
      text-align: center; }
    .c-entry-nav .c-entry-nav__btn a {
      padding: 20px;
      display: block;
      font-size: 16px;
      text-decoration: none;
      color: #0063B2;
      line-height: 1; }
      @media screen and (min-width: 1000px) {
        .c-entry-nav .c-entry-nav__btn a {
          font-size: 18px; }
          .c-entry-nav .c-entry-nav__btn a:hover {
            background-color: #0063B2;
            color: #fff;
            transition: all .2s ease; } }

#p-campaign img {
  width: 100%;
  height: auto; }

#p-application {
  background-image: url(./assets/image/application-bg_sp.png);
  background-size: cover;
  padding: 40px 20px; }
  @media screen and (min-width: 1000px) {
    #p-application {
      background-image: url(./assets/image/application-bg_pc.png);
      padding: 80px; } }
  #p-application h2 {
    text-align: center;
    font-size: 25px; }
    @media screen and (min-width: 1000px) {
      #p-application h2 {
        font-size: 35px; } }
  #p-application p {
    margin-top: 20px;
    font-size: 16px;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      #p-application p {
        margin-top: 30px; }
        #p-application p br {
          display: none; } }
  #p-application .p-application__link {
    margin-top: 30px; }
    @media screen and (min-width: 1000px) {
      #p-application .p-application__link {
        margin-top: 50px; } }

#p-contact {
  background-image: url(./assets/image/contact-bg_sp.png);
  background-size: cover;
  padding: 40px 20px;
  color: #fff; }
  @media screen and (min-width: 1000px) {
    #p-contact {
      background-image: url(./assets/image/contact-bg_pc.png);
      padding: 80px; } }
  #p-contact .p-contact-title {
    margin-left: 0px; }
    @media screen and (min-width: 1000px) {
      #p-contact .p-contact-title {
        position: relative;
        margin-left: 0px;
        z-index: 80; } }
    #p-contact .p-contact-title h2 img {
      width: 100%;
      max-width: 190px;
      height: auto;
      margin-left: 5%; }
      @media screen and (min-width: 1000px) {
        #p-contact .p-contact-title h2 img {
          max-width: 275px;
          margin-left: 200px; } }
    #p-contact .p-contact-title h3 {
      text-align: center;
      font-size: 25px;
      font-weight: 300;
      margin-top: -30px; }
      @media screen and (min-width: 1000px) {
        #p-contact .p-contact-title h3 {
          font-size: 35px; } }
      #p-contact .p-contact-title h3 span {
        display: block;
        font-size: 16px;
        font-weight: 300;
        padding-bottom: 10px; }
    #p-contact .p-contact-title p {
      margin-top: 30px;
      font-size: 16px;
      text-align: center; }
  #p-contact .p-contact-link {
    margin-top: 30px; }
    @media screen and (min-width: 1000px) {
      #p-contact .p-contact-link {
        margin-top: 50px; } }

#header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 1000; }
  #header .header-inner {
    padding: 5px 10px; }
    @media screen and (min-width: 1000px) {
      #header .header-inner {
        padding: 10px 25px;
        display: flex;
        justify-content: space-between;
        width: 100%; } }
  #header .header-logo {
    height: 50px; }
    @media screen and (min-width: 1000px) {
      #header .header-logo {
        height: 60px; } }
    #header .header-logo a {
      display: block; }
    #header .header-logo img {
      height: 50px;
      width: auto; }
      @media screen and (min-width: 1000px) {
        #header .header-logo img {
          height: 60px; } }
  #header .gnav {
    position: absolute;
    display: none;
    top: 60px;
    width: 100%;
    left: 0; }
    @media screen and (min-width: 1000px) {
      #header .gnav {
        position: relative;
        display: inherit;
        top: auto;
        left: auto;
        width: auto;
        margin-right: 150px; } }
    @media screen and (min-width: 1151px) {
      #header .gnav {
        margin-right: 200px; } }
  #header .gnav-list {
    background-color: #E6F4F1;
    padding: 15px 10px 30px; }
    @media screen and (min-width: 1000px) {
      #header .gnav-list {
        padding: 0;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center; } }
    #header .gnav-list ul {
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 1000px) {
        #header .gnav-list ul {
          flex-direction: row; } }
      #header .gnav-list ul li {
        border-bottom: 1px solid #707070; }
        @media screen and (min-width: 1000px) {
          #header .gnav-list ul li {
            border-bottom: 0 solid #000;
            padding: 10px; }
            #header .gnav-list ul li + li a {
              border-left: 1px solid #000;
              padding-left: 20px; } }
        @media screen and (min-width: 1151px) {
          #header .gnav-list ul li {
            padding: 15px; } }
        #header .gnav-list ul li a {
          display: flex;
          justify-content: center;
          color: #3B4043;
          text-decoration: none;
          font-size: 18px;
          padding: 15px; }
          @media screen and (min-width: 1000px) {
            #header .gnav-list ul li a {
              padding: 0;
              line-height: 1;
              font-size: 16px; } }
          #header .gnav-list ul li a:hover {
            opacity: 0.5;
            transition: all .2s ease; }
  #header .header-botton {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000; }
    @media screen and (min-width: 1000px) {
      #header .header-botton {
        top: 0px;
        bottom: auto;
        left: auto;
        right: 0px;
        width: auto; } }
    #header .header-botton a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0063B2;
      color: #fff;
      font-size: 18px;
      text-decoration: none;
      padding: 10px; }
      @media screen and (min-width: 1000px) {
        #header .header-botton a {
          width: 170px;
          height: 80px;
          font-size: 18px;
          text-align: center; } }
      #header .header-botton a:hover {
        opacity: 0.75;
        transition: all .2s ease; }

.menu {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 53px;
  width: 35px;
  z-index: 9999; }
  .menu span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0; }
    .menu span img {
      width: 100%;
      height: auto; }
  @media screen and (min-width: 1000px) {
    .menu {
      display: none; } }

.menu .menu-line {
  position: absolute;
  width: 35px;
  left: 0px;
  height: 3px;
  border-radius: 2px;
  background: #0063B2; }

.menu .menu-line:nth-of-type(1) {
  top: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-line:nth-of-type(2) {
  top: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-line:nth-of-type(3) {
  top: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line {
  background: #0063B2; }

.menu.open .menu-line:nth-of-type(1) {
  top: 16px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line:nth-of-type(2) {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line:nth-of-type(3) {
  top: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-text span {
  width: 30px;
  color: #0063B2;
  position: absolute;
  left: 10px;
  bottom: 4px;
  line-height: 1;
  text-align: center; }

.menu .menu-text span img {
  width: 28px !important;
  height: auto; }

#footer {
  background-color: #E6F4F1; }
  #footer .footer-inner {
    padding: 30px 20px 60px; }
    @media screen and (min-width: 1000px) {
      #footer .footer-inner {
        padding: 60px 40px; } }
  @media screen and (min-width: 1000px) {
    #footer .footer-nav {
      width: 640px;
      margin: 0 auto; } }
  #footer .footer-nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    #footer .footer-nav ul li {
      padding: 5px; }
      @media screen and (min-width: 1000px) {
        #footer .footer-nav ul li {
          padding: 20px; } }
      #footer .footer-nav ul li a {
        color: #3B4043;
        text-decoration: none;
        font-size: 16px; }
  #footer .footer-logo {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 1000px) {
      #footer .footer-logo {
        margin-top: 40px; } }
  #footer .footer-cp {
    margin-top: 20px;
    font-size: 10px;
    color: #5EBFB4;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      #footer .footer-cp {
        margin-top: 40px;
        font-size: 12px; } }

#index {
  min-height: 100vh; }
  #index .index-cover img {
    width: 100%;
    height: auto;
    vertical-align: middle; }
  #index .index-cover__pc {
    background-image: url(./assets/image/index-cover-img_pc.png);
    background-size: cover;
    background-position: left top;
    height: 678px; }
  #index .index-cover-copy {
    margin-left: 50%;
    width: 50%; }
    #index .index-cover-copy .index-cover-copy__01 {
      width: 640px;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px; }
      #index .index-cover-copy .index-cover-copy__01 img {
        width: 340px; }
    #index .index-cover-copy .index-cover-copy__02 {
      width: 640px; }
  #index .index-news {
    background-color: #0063B2; }
  #index .index-news-innner {
    display: flex;
    padding: 10px;
    align-items: center; }
    @media screen and (min-width: 768px) {
      #index .index-news-innner {
        padding: 20px 0;
        width: 100%; } }
    #index .index-news-innner .index-news__title {
      color: #0063B2;
      font-size: 16px;
      background-color: #fff;
      padding: 5px 10px;
      line-height: 1; }
      @media screen and (min-width: 1000px) {
        #index .index-news-innner .index-news__title {
          font-size: 20px;
          padding: 10px 20px;
          width: 150px;
          text-align: center; } }
    #index .index-news-innner .index-news__post {
      margin-left: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-news-innner .index-news__post {
          margin-left: 40px;
          width: calc(100% - 200px); } }
      #index .index-news-innner .index-news__post a {
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        @media screen and (min-width: 1000px) {
          #index .index-news-innner .index-news__post a {
            width: 100%; }
            #index .index-news-innner .index-news__post a:hover dd {
              text-decoration: underline; } }
        #index .index-news-innner .index-news__post a::after {
          content: '';
          width: 10px;
          height: 10px;
          background-image: url(./assets/image/cursor-right.svg);
          background-repeat: no-repeat;
          background-size: auto 100%;
          display: block;
          margin-left: 5px; }
          @media screen and (min-width: 1000px) {
            #index .index-news-innner .index-news__post a::after {
              width: 10px;
              height: 15px; } }
      #index .index-news-innner .index-news__post dl {
        display: flex;
        line-height: 1;
        align-items: center; }
        #index .index-news-innner .index-news__post dl dt {
          margin-right: 10px;
          font-size: 10px;
          line-height: 1;
          padding-top: 5px; }
          @media screen and (min-width: 1000px) {
            #index .index-news-innner .index-news__post dl dt {
              font-size: 14px;
              padding: 0;
              margin-right: 20px; } }
        #index .index-news-innner .index-news__post dl dd {
          font-size: 16px;
          line-height: 1; }
          @media screen and (min-width: 1000px) {
            #index .index-news-innner .index-news__post dl dd {
              font-size: 20px; } }
  #index .index-flow {
    margin-top: 20px; }
    #index .index-flow .index-flow__title {
      text-align: right;
      margin-right: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-flow .index-flow__title {
          position: relative;
          margin-right: 80px;
          z-index: 80; } }
      #index .index-flow .index-flow__title h2 img {
        width: 100%;
        max-width: 268px;
        height: auto; }
        @media screen and (min-width: 1000px) {
          #index .index-flow .index-flow__title h2 img {
            max-width: 450px; } }
    #index .index-flow .index-flow-list {
      display: flex;
      flex-direction: column;
      margin-top: -30px; }
      @media screen and (min-width: 1000px) {
        #index .index-flow .index-flow-list {
          margin-top: -50px; } }
    #index .index-flow .index-flow-block + .index-flow-block {
      margin-top: 50px; }
      @media screen and (min-width: 1000px) {
        #index .index-flow .index-flow-block + .index-flow-block {
          margin-top: 90px; } }
    @media screen and (min-width: 1000px) {
      #index .index-flow .index-flow-block:nth-of-type(even) dl {
        flex-direction: row; }
        #index .index-flow .index-flow-block:nth-of-type(even) dl dd {
          width: 500px;
          height: 320px;
          position: absolute;
          left: auto !important;
          right: 100px;
          top: 0;
          bottom: 0;
          margin: auto; }
          #index .index-flow .index-flow-block:nth-of-type(even) dl dd .flow-block__number {
            right: 10px;
            left: auto !important; } }
    @media screen and (min-width: 1000px) {
      #index .index-flow .index-flow-block dl {
        display: flex;
        flex-direction: row-reverse;
        position: relative; } }
    @media screen and (min-width: 1000px) {
      #index .index-flow .index-flow-block dl dt {
        width: 800px; } }
    #index .index-flow .index-flow-block dl dt img {
      width: 100%;
      height: auto;
      vertical-align: middle; }
    #index .index-flow .index-flow-block dl dd {
      background-color: rgba(228, 243, 240, 0.9);
      margin: -20px 10px 0;
      position: relative;
      z-index: 2;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      padding: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-flow .index-flow-block dl dd {
          width: 500px;
          height: 320px;
          position: absolute;
          left: 100px;
          top: 0;
          bottom: 0;
          margin: auto; } }
      #index .index-flow .index-flow-block dl dd .flow-block__number {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 50px;
        height: 50px; }
        @media screen and (min-width: 1000px) {
          #index .index-flow .index-flow-block dl dd .flow-block__number {
            bottom: auto;
            top: -80px;
            right: auto;
            left: 10px;
            width: 100px;
            height: 100px; } }
        #index .index-flow .index-flow-block dl dd .flow-block__number img {
          width: 100%;
          height: auto; }
      #index .index-flow .index-flow-block dl dd h3 {
        font-size: 25px;
        color: #707070;
        font-weight: 300; }
        @media screen and (min-width: 1000px) {
          #index .index-flow .index-flow-block dl dd h3 {
            font-size: 35px; } }
      #index .index-flow .index-flow-block dl dd p {
        font-size: 16px;
        color: #707070;
        margin-top: 10px;
        padding-bottom: 30px; }
        @media screen and (min-width: 1000px) {
          #index .index-flow .index-flow-block dl dd p {
            margin-top: 20px;
            padding-bottom: 0; } }
  #index .index-trouble {
    margin-top: 60px; }
    @media screen and (min-width: 1000px) {
      #index .index-trouble {
        margin-top: 100px; } }
    #index .index-trouble .index-trouble__title {
      margin-left: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-trouble .index-trouble__title {
          position: relative;
          margin-left: 100px;
          z-index: 80; } }
      #index .index-trouble .index-trouble__title h2 img {
        width: 100%;
        max-width: 200px;
        height: auto; }
        @media screen and (min-width: 1000px) {
          #index .index-trouble .index-trouble__title h2 img {
            max-width: 270px; } }
    #index .index-trouble .index-trouble__copy {
      margin-top: -20px; }
      #index .index-trouble .index-trouble__copy h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300; }
        @media screen and (min-width: 1000px) {
          #index .index-trouble .index-trouble__copy h3 {
            font-size: 35px; } }
        #index .index-trouble .index-trouble__copy h3 span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px; }
    #index .index-trouble .index-trouble__list {
      margin-top: 20px;
      padding: 0 10px; }
      @media screen and (min-width: 1000px) {
        #index .index-trouble .index-trouble__list {
          padding: 0; } }
      #index .index-trouble .index-trouble__list ul {
        display: flex;
        flex-wrap: wrap; }
        #index .index-trouble .index-trouble__list ul li {
          width: 25%;
          margin-top: 20px; }
          @media screen and (min-width: 1000px) {
            #index .index-trouble .index-trouble__list ul li {
              margin-top: 40px; } }
          #index .index-trouble .index-trouble__list ul li dl dt {
            padding: 10px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center; }
            @media screen and (min-width: 1000px) {
              #index .index-trouble .index-trouble__list ul li dl dt {
                height: 100px; } }
            #index .index-trouble .index-trouble__list ul li dl dt img {
              width: auto;
              height: 100%; }
          #index .index-trouble .index-trouble__list ul li dl dd {
            text-align: center;
            font-size: 16px;
            margin-top: 10px;
            font-weight: 300; }
    #index .index-trouble .index-trouble__link {
      margin-top: 50px;
      margin-left: 20px;
      margin-right: 20px; }
  #index .index-trial {
    margin-top: 60px; }
    @media screen and (min-width: 1000px) {
      #index .index-trial {
        margin-top: 100px; } }
    #index .index-trial .index-trial__title {
      margin-left: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-trial .index-trial__title {
          position: relative;
          margin-left: 100px;
          z-index: 80; } }
      #index .index-trial .index-trial__title h2 img {
        width: 100%;
        max-width: 110px;
        height: auto; }
        @media screen and (min-width: 1000px) {
          #index .index-trial .index-trial__title h2 img {
            max-width: 160px; } }
    #index .index-trial .index-trial__copy {
      margin-top: -20px; }
      #index .index-trial .index-trial__copy h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300; }
        @media screen and (min-width: 1000px) {
          #index .index-trial .index-trial__copy h3 {
            font-size: 35px; } }
        #index .index-trial .index-trial__copy h3 span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px; }
      #index .index-trial .index-trial__copy p {
        margin-top: 20px;
        padding: 0 10px; }
        @media screen and (min-width: 1000px) {
          #index .index-trial .index-trial__copy p {
            margin-top: 40px;
            text-align: center;
            line-height: 2;
            font-size: 15px;
            padding: 0; } }
    #index .index-trial .index-trial__figure {
      margin-top: 20px;
      padding: 10px; }
      @media screen and (min-width: 1000px) {
        #index .index-trial .index-trial__figure {
          margin-top: 40px; } }
      #index .index-trial .index-trial__figure img {
        width: 100%;
        height: auto; }
    #index .index-trial .index-trial__link {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-trial .index-trial__link {
          margin-top: 60px; } }
  #index .index-campaign {
    margin-top: 80px;
    padding: 10px; }
    @media screen and (min-width: 1000px) {
      #index .index-campaign {
        margin-top: 100px;
        padding: 0; } }
    #index .index-campaign img {
      width: 100%;
      height: auto; }
  #index .index-application {
    margin-top: 60px; }
    @media screen and (min-width: 1000px) {
      #index .index-application {
        margin-top: 100px; } }
  #index .index-trainer {
    margin-top: 60px; }
    @media screen and (min-width: 1000px) {
      #index .index-trainer {
        margin-top: 150px; } }
    #index .index-trainer img {
      width: 100%;
      height: auto; }
    #index .index-trainer .index-trainer-cover dl {
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 1000px) {
        #index .index-trainer .index-trainer-cover dl {
          flex-direction: row-reverse; } }
      #index .index-trainer .index-trainer-cover dl dt {
        padding: 0 40px; }
        @media screen and (min-width: 1000px) {
          #index .index-trainer .index-trainer-cover dl dt {
            padding: 0;
            width: 640px; } }
      @media screen and (min-width: 1000px) {
        #index .index-trainer .index-trainer-cover dl dd {
          width: 450px;
          margin-right: 50px; } }
    #index .index-trainer .index-trainer-cover .index-trainer__title {
      position: relative; }
      #index .index-trainer .index-trainer-cover .index-trainer__title h2 {
        position: relative;
        left: -10px;
        width: 180px;
        top: 0; }
        @media screen and (min-width: 1000px) {
          #index .index-trainer .index-trainer-cover .index-trainer__title h2 {
            width: 250px; } }
      #index .index-trainer .index-trainer-cover .index-trainer__title p {
        text-align: center;
        font-size: 25px;
        margin-top: -35px; }
        @media screen and (min-width: 1000px) {
          #index .index-trainer .index-trainer-cover .index-trainer__title p {
            font-size: 35px;
            margin-top: -50px; } }
    #index .index-trainer .index-trainer-cover .index-trainer__copy {
      padding: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-trainer .index-trainer-cover .index-trainer__copy {
          padding: 0;
          margin-top: 30px; } }
      #index .index-trainer .index-trainer-cover .index-trainer__copy h3 {
        font-size: 25px; }
        @media screen and (min-width: 1000px) {
          #index .index-trainer .index-trainer-cover .index-trainer__copy h3 {
            font-size: 35px; } }
      #index .index-trainer .index-trainer-cover .index-trainer__copy p {
        margin-top: 15px;
        font-size: 16px; }
        #index .index-trainer .index-trainer-cover .index-trainer__copy p.name {
          text-align: right; }
    #index .index-trainer .index-trainer-profile {
      margin-top: 40px; }
      @media screen and (min-width: 1000px) {
        #index .index-trainer .index-trainer-profile {
          margin-top: 100px; } }
      @media screen and (min-width: 1000px) {
        #index .index-trainer .index-trainer-profile dl {
          display: flex; } }
      #index .index-trainer .index-trainer-profile dl dt {
        padding: 0 40px; }
        @media screen and (min-width: 1000px) {
          #index .index-trainer .index-trainer-profile dl dt {
            padding: 0;
            width: 640px; } }
      #index .index-trainer .index-trainer-profile dl dd {
        margin-top: 40px;
        padding: 0 20px; }
        @media screen and (min-width: 1000px) {
          #index .index-trainer .index-trainer-profile dl dd {
            margin-top: 0;
            padding: 0;
            width: 450px;
            margin-left: 50px; } }
        #index .index-trainer .index-trainer-profile dl dd h3 {
          color: #0063B2;
          border-bottom: 1px solid #0063B2;
          padding-bottom: 5px;
          font-size: 25px; }
        #index .index-trainer .index-trainer-profile dl dd p {
          margin-top: 10px;
          font-size: 16px; }
          @media screen and (min-width: 1000px) {
            #index .index-trainer .index-trainer-profile dl dd p {
              margin-top: 20px; } }
          #index .index-trainer .index-trainer-profile dl dd p + h3 {
            margin-top: 30px; }
            @media screen and (min-width: 1000px) {
              #index .index-trainer .index-trainer-profile dl dd p + h3 {
                margin-top: 40px; } }
  #index .index-menu {
    margin-top: 100px; }
    @media screen and (min-width: 1000px) {
      #index .index-menu {
        margin-top: 100px; } }
    #index .index-menu .index-menu__title {
      margin-left: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-menu .index-menu__title {
          position: relative;
          margin-left: 0px;
          z-index: 80; } }
      #index .index-menu .index-menu__title h2 img {
        width: 100%;
        max-width: 130px;
        height: auto; }
        @media screen and (min-width: 1000px) {
          #index .index-menu .index-menu__title h2 img {
            max-width: 200px;
            margin-left: 200px; } }
      #index .index-menu .index-menu__title h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -30px; }
        @media screen and (min-width: 1000px) {
          #index .index-menu .index-menu__title h3 {
            font-size: 35px; } }
        #index .index-menu .index-menu__title h3 span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px; }
    #index .index-menu .index-menu__list {
      padding: 20px;
      margin-top: 20px; }
      #index .index-menu .index-menu__list ul {
        display: flex;
        flex-direction: column; }
        @media screen and (min-width: 1000px) {
          #index .index-menu .index-menu__list ul {
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -30px; } }
        #index .index-menu .index-menu__list ul li {
          background-color: rgba(228, 243, 240, 0.9);
          padding: 20px; }
          @media screen and (min-width: 1000px) {
            #index .index-menu .index-menu__list ul li {
              width: calc(50% - 30px);
              margin-left: 30px;
              margin-bottom: 30px; } }
          #index .index-menu .index-menu__list ul li + li {
            margin-top: 20px; }
            @media screen and (min-width: 1000px) {
              #index .index-menu .index-menu__list ul li + li {
                margin-top: 0; } }
          #index .index-menu .index-menu__list ul li dl dt {
            text-align: center;
            color: #0063B2;
            font-size: 20px; }
            @media screen and (min-width: 1000px) {
              #index .index-menu .index-menu__list ul li dl dt {
                font-size: 25px; } }
          #index .index-menu .index-menu__list ul li dl dd {
            margin-top: 20px;
            font-size: 16px; }
    #index .index-menu .index-menu__link {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-menu .index-menu__link {
          margin-top: 20px; } }
  #index .index-access {
    padding-top: 130px;
    margin-top: -100px; }
    @media screen and (min-width: 1000px) {
      #index .index-access {
        margin-top: 100px; } }
    @media screen and (min-width: 1000px) {
      #index .index-access .index-access-body {
        display: flex; } }
    #index .index-access .index-access__title {
      margin-left: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-access .index-access__title {
          position: relative;
          margin-left: 0px;
          z-index: 80;
          width: calc(100% - 640px);
          padding-left: 140px; } }
      #index .index-access .index-access__title h2 img {
        width: 100%;
        max-width: 180px;
        height: auto; }
        @media screen and (min-width: 1000px) {
          #index .index-access .index-access__title h2 img {
            max-width: 250px;
            margin-left: 0px; } }
      #index .index-access .index-access__title h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -30px; }
        @media screen and (min-width: 1000px) {
          #index .index-access .index-access__title h3 {
            font-size: 35px;
            margin-top: -50px; } }
        #index .index-access .index-access__title h3 span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px; }
      #index .index-access .index-access__title .access-logo {
        margin-top: 30px;
        text-align: center; }
      #index .index-access .index-access__title .access-info {
        margin-top: 20px;
        text-align: center; }
      #index .index-access .index-access__title .access-sns {
        margin-top: 20px; }
        #index .index-access .index-access__title .access-sns ul {
          display: flex;
          align-items: center;
          justify-content: center; }
          #index .index-access .index-access__title .access-sns ul li {
            margin: 0 10px; }
            #index .index-access .index-access__title .access-sns ul li a {
              opacity: 1;
              transition: all .2s ease; }
              #index .index-access .index-access__title .access-sns ul li a:hover {
                opacity: 0.6; }
    #index .index-access .index-access__map {
      margin-top: 40px; }
      @media screen and (min-width: 1000px) {
        #index .index-access .index-access__map {
          width: 640px;
          margin-top: 0; } }
  #index .index-blog {
    margin-top: 100px; }
    @media screen and (min-width: 1000px) {
      #index .index-blog {
        margin-top: 100px; } }
    #index .index-blog .index-blog__title {
      margin-left: 0px; }
      @media screen and (min-width: 1000px) {
        #index .index-blog .index-blog__title {
          position: relative;
          margin-left: 0px;
          z-index: 80; } }
      #index .index-blog .index-blog__title h2 img {
        width: 100%;
        max-width: 110px;
        height: auto;
        margin-left: 20%; }
        @media screen and (min-width: 1000px) {
          #index .index-blog .index-blog__title h2 img {
            max-width: 150px;
            margin-left: 300px; } }
      #index .index-blog .index-blog__title h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 300;
        margin-top: -20px; }
        @media screen and (min-width: 1000px) {
          #index .index-blog .index-blog__title h3 {
            font-size: 35px; } }
        #index .index-blog .index-blog__title h3 span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding-bottom: 10px; }
    #index .index-blog .index-blog__list {
      margin-top: 40px;
      padding: 0 10px; }
      #index .index-blog .index-blog__list ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px; }
        @media screen and (min-width: 1000px) {
          #index .index-blog .index-blog__list ul {
            margin-left: -20px; } }
        #index .index-blog .index-blog__list ul .index-blog__item {
          width: calc(50% - 10px);
          margin-left: 10px;
          margin-bottom: 20px; }
          @media screen and (min-width: 1000px) {
            #index .index-blog .index-blog__list ul .index-blog__item {
              width: calc(25% - 20px);
              margin-left: 20px; } }
          #index .index-blog .index-blog__list ul .index-blog__item img {
            width: 100%;
            height: auto; }
          #index .index-blog .index-blog__list ul .index-blog__item a {
            text-decoration: none;
            color: #0063B2; }
            @media screen and (min-width: 1000px) {
              #index .index-blog .index-blog__list ul .index-blog__item a {
                display: block; }
                #index .index-blog .index-blog__list ul .index-blog__item a:hover {
                  opacity: 0.6;
                  transition: all .2s ease; } }
          #index .index-blog .index-blog__list ul .index-blog__item .date {
            font-size: 10px;
            color: #3B4043; }
            @media screen and (min-width: 1000px) {
              #index .index-blog .index-blog__list ul .index-blog__item .date {
                font-size: 14px; } }
          #index .index-blog .index-blog__list ul .index-blog__item .title {
            font-size: 12px; }
            @media screen and (min-width: 1000px) {
              #index .index-blog .index-blog__list ul .index-blog__item .title {
                font-size: 16px; } }
    #index .index-blog .index-blog__link {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px; }
      @media screen and (min-width: 1000px) {
        #index .index-blog .index-blog__link {
          margin-top: 20px; } }
  #index .index-contact {
    margin-top: 60px; }
    @media screen and (min-width: 1000px) {
      #index .index-contact {
        margin-top: 150px; } }

#worries .worries-header {
  background-image: url(./assets/image/worries/worries-header_sp.png); }
  @media screen and (min-width: 1000px) {
    #worries .worries-header {
      background-image: url(./assets/image/worries/worries-header_pc.png); } }

#worries .worries-list {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px; }
  @media screen and (min-width: 1000px) {
    #worries .worries-list {
      margin-top: 100px;
      margin-left: -20px;
      padding: 0; } }
  #worries .worries-list .entry {
    width: calc(50% - 10px);
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: #0063B2;
    min-height: 130px; }
    @media screen and (min-width: 1000px) {
      #worries .worries-list .entry {
        width: calc(25% - 20px);
        margin-left: 20px;
        margin-bottom: 20px; } }
    #worries .worries-list .entry a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      text-align: center;
      padding: 20px;
      height: 100%; }
      @media screen and (min-width: 1000px) {
        #worries .worries-list .entry a {
          font-size: 20px;
          padding: 40px; }
          #worries .worries-list .entry a:hover {
            opacity: 0.5;
            transition: all .2s ease; } }

#blog .blog-header {
  background-image: url(./assets/image/blog/blog-header_sp.png); }
  @media screen and (min-width: 1000px) {
    #blog .blog-header {
      background-image: url(./assets/image/blog/blog-header_pc.png); } }

#blog .blog-list {
  margin-top: 70px;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px; }
  @media screen and (min-width: 1000px) {
    #blog .blog-list {
      margin-left: -20px;
      padding: 0; } }
  #blog .blog-list .entry {
    width: calc(50% - 10px);
    margin-left: 10px;
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      #blog .blog-list .entry {
        width: calc(33.333% - 10px); } }
    @media screen and (min-width: 1000px) {
      #blog .blog-list .entry {
        width: calc(25% - 20px);
        margin-left: 20px; } }
    #blog .blog-list .entry a {
      display: block;
      text-decoration: none;
      color: #0063B2; }
      @media screen and (min-width: 1000px) {
        #blog .blog-list .entry a:hover {
          opacity: 0.5;
          transition: all .2s ease; } }
    #blog .blog-list .entry dl dt img {
      width: 100%;
      height: auto;
      vertical-align: middle; }
    #blog .blog-list .entry dl dd {
      margin-top: 10px; }
      #blog .blog-list .entry dl dd .entry-date {
        color: #3B4043; }
      #blog .blog-list .entry dl dd .entry-title {
        font-size: 14px; }
        @media screen and (min-width: 1000px) {
          #blog .blog-list .entry dl dd .entry-title {
            font-size: 16px; } }

.side-nav {
  padding: 60px 40px 0; }
  @media screen and (min-width: 1000px) {
    .side-nav {
      display: flex;
      padding: 0;
      margin-top: 80px;
      margin-bottom: 90px;
      justify-content: space-between; } }
  .side-nav .side-nav-col {
    margin-bottom: 20px;
    border: 1px solid #0063B2; }
    @media screen and (min-width: 1000px) {
      .side-nav .side-nav-col {
        width: 30%; } }
    .side-nav .side-nav-col form {
      display: block;
      width: 100%; }
    .side-nav .side-nav-col select {
      background-color: #fff;
      display: block;
      width: 100%;
      padding: 10px 20px;
      position: relative;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      font-size: 14px;
      font-weight: 300;
      border-radius: 4px;
      color: #3B4043; }
    .side-nav .side-nav-col label {
      position: relative;
      display: block; }
      .side-nav .side-nav-col label:after {
        content: "\f078";
        font-family: FontAwesome;
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 12px;
        color: #707070;
        display: block;
        pointer-events: none; }

#contact .contact-header {
  background-image: url(./assets/image/contact/contact-header_sp.png); }
  @media screen and (min-width: 1000px) {
    #contact .contact-header {
      background-image: url(./assets/image/contact/contact-header_pc.png); } }

#contact .contact-copy {
  margin-top: 60px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    #contact .contact-copy {
      margin-top: 100px;
      padding: 0; } }
  #contact .contact-copy h2 {
    font-size: 25px;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      #contact .contact-copy h2 {
        font-size: 35px; } }
  #contact .contact-copy p {
    margin-top: 40px;
    font-size: 16px; }
    @media screen and (min-width: 1000px) {
      #contact .contact-copy p {
        text-align: center;
        margin-top: 50px; } }
  #contact .contact-copy .c-container__tel {
    margin-top: 35px;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      #contact .contact-copy .c-container__tel {
        margin-top: 65px; } }
    #contact .contact-copy .c-container__tel span {
      display: block;
      font-size: 12px; }
    #contact .contact-copy .c-container__tel strong {
      font-size: 35px; }

#contact .contact-form {
  padding: 0 20px;
  margin-top: 70px; }
  #contact .contact-form .c-sectiontitle {
    font-size: 23px; }
    @media screen and (min-width: 1000px) {
      #contact .contact-form .c-sectiontitle {
        margin-top: 130px;
        font-size: 35px; } }
  #contact .contact-form .contact-form__copy {
    margin-top: 30px;
    font-size: 16px; }
  #contact .contact-form .contact-form-body {
    margin-top: 40px;
    max-width: 600px;
    margin: 40px auto 0; }
    @media screen and (min-width: 1000px) {
      #contact .contact-form .contact-form-body {
        max-width: 100%;
        margin: 80px 0; } }
    #contact .contact-form .contact-form-body ::placeholder {
      color: #ccc; }
    #contact .contact-form .contact-form-body textarea {
      padding: 5px;
      background-color: #fff;
      border: 1px solid #0063B2;
      width: 100%;
      font-size: 16px;
      padding: 10px;
      font-weight: 300 !important;
      border-radius: 0; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body textarea {
          padding: 5px;
          max-width: 500px; } }
    #contact .contact-form .contact-form-body input {
      width: 100%;
      background-color: #fff;
      border: 1px solid #0063B2;
      padding: 10px;
      font-weight: 300 !important;
      font-size: 16px;
      border-radius: 0; }
      #contact .contact-form .contact-form-body input[type="radio"] {
        width: auto !important; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body input {
          max-width: 100%;
          padding: 10px 20px; } }
    #contact .contact-form .contact-form-body select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      outline: none;
      background: transparent;
      border-radius: 0;
      width: 100%;
      border: 1px solid #0063B2;
      padding: 10px;
      font-weight: 300 !important;
      background-image: url(./assets/image/cursor-down.png);
      background-position: center right 10px;
      background-repeat: no-repeat;
      background-size: 10px auto; }
      #contact .contact-form .contact-form-body select option {
        text-align: left;
        font-weight: 300 !important; }
    #contact .contact-form .contact-form-body .contact-form-item dl {
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body .contact-form-item dl {
          flex-direction: row; } }
      #contact .contact-form .contact-form-body .contact-form-item dl dt {
        font-size: 16px;
        display: flex;
        justify-content: space-between; }
        @media screen and (min-width: 1000px) {
          #contact .contact-form .contact-form-body .contact-form-item dl dt {
            align-items: flex-start;
            width: 200px; } }
        #contact .contact-form .contact-form-body .contact-form-item dl dt span {
          background-color: #0063B2;
          color: #fff;
          font-size: 12px;
          padding: 5px 10px; }
      #contact .contact-form .contact-form-body .contact-form-item dl dd {
        margin-top: 10px; }
        @media screen and (min-width: 1000px) {
          #contact .contact-form .contact-form-body .contact-form-item dl dd {
            margin-top: 0;
            width: calc(100% - 200px);
            padding-left: 60px; } }
    #contact .contact-form .contact-form-body .contact-form-item.is--checkbox dt {
      justify-content: flex-start; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body .contact-form-item.is--checkbox dt {
          justify-content: space-between; } }
      #contact .contact-form .contact-form-body .contact-form-item.is--checkbox dt span {
        margin-left: 20px; }
    #contact .contact-form .contact-form-body .contact-form-item .horizontal-item {
      display: block;
      margin-left: 0;
      margin-top: 10px;
      font-size: 16px; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body .contact-form-item .horizontal-item {
          margin-bottom: 20px;
          margin-top: 0; } }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item .mwform-radio-field-text {
        font-weight: 300 !important; }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item input {
        position: absolute;
        white-space: nowrap;
        width: 1px;
        height: 1px;
        overflow: hidden;
        border: 0;
        padding: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        margin: -1px; }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item label {
        cursor: pointer;
        padding-left: 40px;
        position: relative; }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item span::before,
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item span::after {
        content: "";
        display: block;
        position: absolute; }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item span::before {
        background-color: #fff;
        border-radius: 0%;
        border: 1px solid #0063B2;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);
        top: 50%;
        left: 0px; }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item span::after {
        border-bottom: 2px solid #0063B2;
        border-left: 2px solid #0063B2;
        opacity: 0;
        height: 10px;
        width: 20px;
        transform: rotate(-45deg);
        top: 2px;
        left: 5px; }
      #contact .contact-form .contact-form-body .contact-form-item .horizontal-item input:checked + span::after {
        opacity: 1; }
    #contact .contact-form .contact-form-body .contact-form-item + .contact-form-item {
      margin-top: 30px; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body .contact-form-item + .contact-form-item {
          margin-top: 40px; } }
    #contact .contact-form .contact-form-body .contact-form-text {
      margin-top: 40px;
      font-size: 16px; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body .contact-form-text {
          margin-top: 100px;
          font-size: 18px;
          text-decoration: underline; }
          #contact .contact-form .contact-form-body .contact-form-text.is--center {
            text-align: center; } }
    #contact .contact-form .contact-form-body .contact-form-item-col2 {
      margin-top: 30px; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form-body .contact-form-item-col2 {
          font-size: 16px; } }
      #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 {
        display: flex;
        justify-content: space-between; }
        #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2__m {
          width: 60px; }
          @media screen and (min-width: 1000px) {
            #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2__m {
              width: 200px; } }
        #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2__s {
          width: calc(100% - 80px); }
          @media screen and (min-width: 1000px) {
            #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2__s {
              width: calc(100% - 200px);
              padding-left: 60px; } }
        #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2-sub {
          display: flex;
          align-items: center; }
          #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2-sub + .item-col2-sub {
            margin-top: 20px; }
          #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2-sub .item-col2-sub__m {
            width: 60px;
            text-align: right; }
            @media screen and (min-width: 1000px) {
              #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2-sub .item-col2-sub__m {
                text-align: left; } }
          #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2-sub .item-col2-sub__s {
            width: calc(100% - 60px);
            padding-left: 20px; }
            @media screen and (min-width: 1000px) {
              #contact .contact-form .contact-form-body .contact-form-item-col2 .item-col2 .item-col2-sub .item-col2-sub__s select {
                max-width: 300px; } }
      #contact .contact-form .contact-form-body .contact-form-item-col2 + .contact-form-item-col2 {
        margin-top: 50px; }
      #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea {
        margin-top: 60px; }
        @media screen and (min-width: 1000px) {
          #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea {
            margin-top: 80px; } }
        #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 {
          flex-direction: column;
          justify-content: flex-start; }
          @media screen and (min-width: 1000px) {
            #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 {
              flex-direction: row; } }
          #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__m {
            width: 100%;
            font-size: 16px; }
            @media screen and (min-width: 1000px) {
              #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__m {
                width: 200px; } }
          #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__s {
            width: 100%;
            font-size: 16px;
            margin-top: 25px; }
            @media screen and (min-width: 1000px) {
              #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__s {
                width: calc(100% - 200px);
                padding-left: 60px;
                margin-top: 0; } }
            #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__s .textarea {
              margin-top: 20px; }
              @media screen and (min-width: 1000px) {
                #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__s .textarea {
                  width: 100%; }
                  #contact .contact-form .contact-form-body .contact-form-item-col2.is--textarea .item-col2 .item-col2__s .textarea textarea {
                    max-width: 100%;
                    min-height: 300px; } }
  #contact .contact-form .contact-form__submit {
    margin: 20px auto 40px;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      #contact .contact-form .contact-form__submit {
        margin-top: 60px;
        margin-bottom: 150px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center; } }
    #contact .contact-form .contact-form__submit input {
      max-width: 400px;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      padding: 20px;
      margin: 10px auto;
      color: #0063B2;
      transition: all 0.2s ease;
      letter-spacing: .2em;
      width: 80%;
      border: 1px solid #0063B2;
      font-size: 18px; }
      @media screen and (min-width: 1000px) {
        #contact .contact-form .contact-form__submit input {
          width: 100%;
          margin: 0px 20px;
          display: inline-block;
          padding: 20px 30px;
          font-size: 20px; } }
      #contact .contact-form .contact-form__submit input:hover {
        background-color: #0063B2;
        color: #fff; }
      #contact .contact-form .contact-form__submit input:first-child {
        background-color: #0063B2;
        color: #fff; }
        #contact .contact-form .contact-form__submit input:first-child:hover {
          opacity: 0.7; }

#contact .mw_wp_form_preview::before {
  content: "フォーム入力確認";
  position: relative;
  top: 20px;
  display: block;
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  text-align: center;
  color: #0063B2; }
  @media screen and (min-width: 1000px) {
    #contact .mw_wp_form_preview::before {
      top: 50px;
      font-size: 25px; } }

#contact .mw_wp_form_preview .contact-form-item {
  border: 1px solid #ccc;
  padding: 10px; }
  #contact .mw_wp_form_preview .contact-form-item + .contact-form-item {
    margin-top: 10px !important; }
  #contact .mw_wp_form_preview .contact-form-item dt {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px; }
    @media screen and (min-width: 1000px) {
      #contact .mw_wp_form_preview .contact-form-item dt {
        border-bottom: 0px solid #000;
        border-right: 1px solid #ccc;
        padding-bottom: 0; } }
    #contact .mw_wp_form_preview .contact-form-item dt span {
      display: none; }
  #contact .mw_wp_form_preview .contact-form-item dd {
    font-size: 16px; }

#contact .mw_wp_form_preview .contact-form-item-col2 {
  border: 1px solid #ccc;
  padding: 10px; }
  #contact .mw_wp_form_preview .contact-form-item-col2 + .contact-form-item-col2 {
    margin-top: 10px !important; }
  #contact .mw_wp_form_preview .contact-form-item-col2 .item-col2 > .item-col2__m {
    border-right: 1px solid #ccc;
    padding-right: 10px;
    width: 100px;
    display: flex;
    align-items: center; }
  #contact .mw_wp_form_preview .contact-form-item-col2.is--textarea .item-col2 .item-col2__m {
    width: 100%;
    border-right: 0px solid #000;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 1000px) {
      #contact .mw_wp_form_preview .contact-form-item-col2.is--textarea .item-col2 .item-col2__m {
        border-bottom: 0px solid #000;
        border-right: 1px solid #ccc;
        padding-bottom: 0; } }
  #contact .mw_wp_form_preview .contact-form-item-col2.is--textarea .item-col2 .item-col2__s {
    margin-top: 0 !important; }
    #contact .mw_wp_form_preview .contact-form-item-col2.is--textarea .item-col2 .item-col2__s .textarea {
      margin-top: 0 !important; }

#contact .mw_wp_form_preview .item-col2__s p {
  display: none; }

#contact .mw_wp_form_preview .contact-form-text {
  display: none; }

#contact .mw_wp_form_complete {
  margin: 0px 20px;
  margin-top: 100px;
  padding: 40px 20px;
  margin-bottom: 100px;
  background: #eee; }
  @media screen and (min-width: 1000px) {
    #contact .mw_wp_form_complete {
      padding: 20px;
      margin-top: 100px;
      margin-bottom: 20px;
      margin-bottom: 150px; } }
  #contact .mw_wp_form_complete .thanks-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px; }
    @media screen and (min-width: 1000px) {
      #contact .mw_wp_form_complete .thanks-heading {
        font-size: 35px; } }
  #contact .mw_wp_form_complete .thanks-txt {
    margin-bottom: 40px; }
  #contact .mw_wp_form_complete .thanks-back {
    width: 200px;
    background-color: #0063B2;
    margin: 0 auto;
    transition: all 0.2s ease; }
    #contact .mw_wp_form_complete .thanks-back:hover {
      background-color: #0063B2;
      opacity: 0.7; }
    #contact .mw_wp_form_complete .thanks-back a {
      display: block;
      text-align: center;
      padding: 20px;
      color: #fff;
      letter-spacing: 0.1em;
      text-decoration: none; }

#lesson .lesson-header {
  background-image: url(./assets/image/lesson/lesson-header_sp.png); }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-header {
      background-image: url(./assets/image/lesson/lesson-header_pc.png); } }

#lesson .lesson-contents {
  margin-top: 75px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-contents {
      margin-top: 140px; } }

#lesson .lesson-about {
  margin-top: 65px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-about {
      margin-top: 120px;
      padding: 0; } }
  #lesson .lesson-about h2 {
    font-size: 25px;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-about h2 {
        font-size: 35px; } }
  #lesson .lesson-about p {
    margin-top: 25px;
    font-size: 16px;
    line-height: 1.9; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-about p {
        text-align: center; } }
    #lesson .lesson-about p + h2 {
      margin-top: 40px; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-about p + h2 {
          margin-top: 100px; } }

#lesson .lesson-flow {
  margin-top: 60px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-flow {
      margin-top: 125px;
      padding: 0; } }
  #lesson .lesson-flow .lesson-flow__list {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    padding: 0 20px; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-flow .lesson-flow__list {
        padding: 0;
        margin-top: 70px;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -30px; } }
    #lesson .lesson-flow .lesson-flow__list li {
      background-color: #E6F4F1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px;
      margin-bottom: 30px; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-flow .lesson-flow__list li {
          width: calc(33.333% - 30px);
          margin-left: 30px; } }
      #lesson .lesson-flow .lesson-flow__list li dl dt {
        text-align: center; }
        #lesson .lesson-flow .lesson-flow__list li dl dt img {
          height: 120px;
          width: auto; }
      #lesson .lesson-flow .lesson-flow__list li dl dd {
        text-align: center;
        margin-top: 10px; }
        #lesson .lesson-flow .lesson-flow__list li dl dd h3 {
          font-size: 24px;
          font-weight: 500;
          color: #0063B2; }
        #lesson .lesson-flow .lesson-flow__list li dl dd p {
          margin-top: 10px;
          text-align: center;
          font-weight: 500; }
          #lesson .lesson-flow .lesson-flow__list li dl dd p a {
            color: #0063B2; }

#lesson .lesson-access {
  margin-top: 100px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-access {
      margin-top: 100px; } }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-access .lesson-access-body {
      display: flex;
      justify-content: space-between; } }
  #lesson .lesson-access .lesson-access__title {
    margin-left: 20px; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-access .lesson-access__title {
        position: relative;
        margin-left: 0px;
        z-index: 80;
        width: calc(100% - 640px); } }
    #lesson .lesson-access .lesson-access__title h2 img {
      width: 100%;
      max-width: 180px;
      height: auto; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-access .lesson-access__title h2 img {
          max-width: 250px;
          margin-left: 0px; } }
    #lesson .lesson-access .lesson-access__title h3 {
      text-align: center;
      font-size: 25px;
      font-weight: 300;
      margin-top: -30px; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-access .lesson-access__title h3 {
          font-size: 35px;
          margin-top: -50px; } }
      #lesson .lesson-access .lesson-access__title h3 span {
        display: block;
        font-size: 16px;
        font-weight: 300;
        padding-bottom: 10px; }
    #lesson .lesson-access .lesson-access__title .access-logo {
      margin-top: 30px;
      text-align: center; }
    #lesson .lesson-access .lesson-access__title .access-info {
      margin-top: 20px;
      text-align: center; }
    #lesson .lesson-access .lesson-access__title .access-sns {
      margin-top: 20px; }
      #lesson .lesson-access .lesson-access__title .access-sns ul {
        display: flex;
        align-items: center;
        justify-content: center; }
        #lesson .lesson-access .lesson-access__title .access-sns ul li {
          margin: 0 10px; }
          #lesson .lesson-access .lesson-access__title .access-sns ul li a {
            opacity: 1;
            transition: all .2s ease; }
            #lesson .lesson-access .lesson-access__title .access-sns ul li a:hover {
              opacity: 0.6; }
  #lesson .lesson-access .lesson-access__map {
    margin-top: 40px; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-access .lesson-access__map {
        width: 600px;
        margin-top: 0; } }

#lesson .lesson-parking {
  margin-top: 70px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-parking {
      margin-top: 120px;
      padding: 0 20px; } }
  #lesson .lesson-parking dl {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-parking dl {
        flex-direction: row; } }
    #lesson .lesson-parking dl dt {
      text-align: center; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-parking dl dt {
          width: 540px; } }
      #lesson .lesson-parking dl dt h2 {
        font-size: 30px; }
        @media screen and (min-width: 1000px) {
          #lesson .lesson-parking dl dt h2 {
            font-size: 35px; } }
      #lesson .lesson-parking dl dt p {
        font-size: 14px;
        margin-top: 20px; }
    #lesson .lesson-parking dl dd {
      padding: 20px;
      text-align: center; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-parking dl dd {
          width: 300px; } }
      #lesson .lesson-parking dl dd img {
        max-width: 100%;
        width: auto; }

#lesson .lesson-procedure {
  margin-top: 90px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-procedure {
      margin-top: 240px;
      padding: 0; } }
  #lesson .lesson-procedure .lesson-procedure__list {
    display: flex;
    flex-direction: column;
    margin-top: 50px; }
    @media screen and (min-width: 1000px) {
      #lesson .lesson-procedure .lesson-procedure__list {
        flex-direction: row;
        flex-wrap: wrap; } }
    #lesson .lesson-procedure .lesson-procedure__list li + li {
      margin-top: 50px; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-procedure .lesson-procedure__list li + li {
          margin-top: 80px; } }
    #lesson .lesson-procedure .lesson-procedure__list dl {
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-procedure .lesson-procedure__list dl {
          flex-direction: row; } }
      @media screen and (min-width: 1000px) {
        #lesson .lesson-procedure .lesson-procedure__list dl dt {
          width: calc(100% - 330px);
          padding-right: 30px; } }
      #lesson .lesson-procedure .lesson-procedure__list dl dt span {
        margin-right: 15px; }
        #lesson .lesson-procedure .lesson-procedure__list dl dt span img {
          height: 70px;
          width: auto; }
          @media screen and (min-width: 1000px) {
            #lesson .lesson-procedure .lesson-procedure__list dl dt span img {
              height: 90px; } }
      #lesson .lesson-procedure .lesson-procedure__list dl dt h3 {
        font-size: 25px;
        line-height: 1.3;
        display: flex;
        align-items: center; }
        @media screen and (min-width: 1000px) {
          #lesson .lesson-procedure .lesson-procedure__list dl dt h3 {
            font-size: 35px; } }
      #lesson .lesson-procedure .lesson-procedure__list dl dt p {
        font-size: 16px;
        margin-top: 10px; }
      #lesson .lesson-procedure .lesson-procedure__list dl dd {
        margin-top: 10px; }
        @media screen and (min-width: 1000px) {
          #lesson .lesson-procedure .lesson-procedure__list dl dd {
            margin-top: 0;
            width: 330px; } }
        #lesson .lesson-procedure .lesson-procedure__list dl dd img {
          width: 100%;
          height: auto;
          vertical-align: middle; }

#lesson .lesson-application {
  margin-top: 100px; }
  @media screen and (min-width: 1000px) {
    #lesson .lesson-application {
      margin-top: 150px; } }

#system .system-header {
  background-image: url(./assets/image/system/system-header_sp.png); }
  @media screen and (min-width: 1000px) {
    #system .system-header {
      background-image: url(./assets/image/system/system-header_pc.png); } }

#system .system-contents {
  margin-top: 60px; }
  @media screen and (min-width: 1000px) {
    #system .system-contents {
      margin-top: 100px; } }

#system .system-contents__title {
  text-align: center; }
  #system .system-contents__title h2 {
    font-size: 25px; }
  #system .system-contents__title p {
    margin-top: 10px;
    font-size: 16px; }

#system .system-memberprice {
  margin-top: 60px;
  padding: 0 20px; }
  #system .system-memberprice .system-memberprice__price {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 2px solid #0063B2;
    padding: 20px; }
    #system .system-memberprice .system-memberprice__price .strong {
      font-size: 35px;
      color: #0063B2; }
    #system .system-memberprice .system-memberprice__price p {
      font-size: 16px; }

#system .system-memberprice__list {
  margin-top: 50px;
  padding: 0 20px;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1000px) {
    #system .system-memberprice__list {
      margin-top: 80px;
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -30px; } }

@media screen and (min-width: 1000px) {
  #system .system-memberprice__item {
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-bottom: 50px; } }

#system .system-memberprice__item h3 {
  background-color: #E4F3F0;
  padding: 20px;
  font-size: 20px;
  text-align: center; }

#system .system-memberprice__item p {
  margin-top: 20px;
  font-size: 16px; }

#system .system-memberprice__item + .system-memberprice__item {
  margin-top: 50px; }
  @media screen and (min-width: 1000px) {
    #system .system-memberprice__item + .system-memberprice__item {
      margin-top: 0; } }

#system .system-memberprice__item .price-table {
  max-width: 400px;
  width: 100%;
  margin: 50px auto 0; }
  @media screen and (min-width: 1000px) {
    #system .system-memberprice__item .price-table {
      max-width: 100%;
      margin: 0;
      margin-top: 50px; } }
  #system .system-memberprice__item .price-table table {
    width: 100%;
    font-size: 16px; }
    #system .system-memberprice__item .price-table table + p {
      margin-top: 5px;
      text-align: right;
      font-size: 11px;
      color: #0063B2; }
      @media screen and (min-width: 1000px) {
        #system .system-memberprice__item .price-table table + p {
          font-size: 16px; } }
  #system .system-memberprice__item .price-table th {
    border-bottom: 1px solid #0063B2;
    text-align: center;
    font-size: 12px;
    padding-bottom: 10px; }
  #system .system-memberprice__item .price-table td {
    width: 50%;
    border-bottom: 1px solid #0063B2;
    font-size: 17px;
    color: #0063B2;
    padding: 10px; }
    @media screen and (min-width: 1000px) {
      #system .system-memberprice__item .price-table td {
        font-size: 22px;
        padding: 10px 20px; } }
    #system .system-memberprice__item .price-table td span {
      font-size: 12px; }
      @media screen and (min-width: 1000px) {
        #system .system-memberprice__item .price-table td span {
          font-size: 16px; } }
    #system .system-memberprice__item .price-table td + td {
      border-left: 1px solid #0063B2;
      font-size: 21px;
      text-align: center; }
      @media screen and (min-width: 1000px) {
        #system .system-memberprice__item .price-table td + td {
          font-size: 27px;
          text-align: right;
          padding-right: 50px;
          position: relative; }
          #system .system-memberprice__item .price-table td + td span {
            position: absolute;
            right: 25px; } }

#system .system-lesson {
  margin-top: 90px;
  background-image: url(./assets/image/system/sp_trialback.png);
  background-size: cover;
  padding: 40px 60px;
  min-height: 300px; }
  @media screen and (min-width: 1000px) {
    #system .system-lesson {
      margin-top: 110px;
      background-image: url(./assets/image/system/trialback@2x.png);
      padding: 50px 70px; } }
  #system .system-lesson h3 {
    font-size: 25px;
    color: #0063B2; }
  #system .system-lesson dl {
    margin-top: 20px;
    display: flex; }
    @media screen and (min-width: 1000px) {
      #system .system-lesson dl {
        width: 50%; } }
    #system .system-lesson dl dt, #system .system-lesson dl dd {
      width: 50%;
      font-size: 22px;
      color: #0063B2; }
  #system .system-lesson p {
    margin-top: 30px;
    font-size: 16px; }

#system .system-payment {
  margin-top: 70px;
  padding: 0 20px; }
  @media screen and (min-width: 1000px) {
    #system .system-payment {
      padding: 0;
      margin-top: 100px; } }
  #system .system-payment p {
    font-size: 16px;
    margin-top: 20px; }
  #system .system-payment ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px; }
    #system .system-payment ul li {
      width: 33.333%;
      padding: 10px;
      text-align: center; }
      @media screen and (min-width: 1000px) {
        #system .system-payment ul li {
          width: 16.6666%; } }
      #system .system-payment ul li img {
        width: auto;
        height: 60px; }
        @media screen and (min-width: 1000px) {
          #system .system-payment ul li img {
            height: 80px; } }

#system .system-campaign {
  padding: 20px;
  margin-top: 60px; }
  @media screen and (min-width: 1000px) {
    #system .system-campaign {
      padding: 0;
      margin-top: 100px;
      margin-bottom: 100px; } }
