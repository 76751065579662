#header {
  background-color: $color-white;
  position: fixed;
  top:0;
  width: 100%;
  left:0;
  z-index: 1000;

  .header-inner {
    padding: 5px 10px;

    @include media-up(lg){
      padding: 10px 25px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .header-logo {
    height: 50px;

    @include media-up(lg){
      height: 60px;
    }
    a {
      display: block;
    }

    img {
      height: 50px;
      width: auto;

      @include media-up(lg){
        height: 60px;
      }
    }
  }

  .gnav {
    position: absolute;
    display: none;
    top: 60px;
    width: 100%;
    left:0;

    @include media-up(lg){
      position: relative;
      display: inherit;
      top: auto;
      left: auto;
      width: auto;
      margin-right: 150px;
    }

    @include media-up(xls){
      margin-right: 200px;
    }
  }

  .gnav-list {
    background-color: #E6F4F1;
    padding: 15px 10px 30px;

    @include media-up(lg){
      padding: 0;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ul {
      display: flex;
      flex-direction: column;

      @include media-up(lg){
        flex-direction: row;
      }

      li {
        border-bottom: 1px solid $color-gray;

        @include media-up(lg){
          border-bottom: 0 solid #000;
          padding: 10px;

          + li {

            a {
              border-left: 1px solid #000;
              padding-left: 20px;
            }
          }
        }

        @include media-up(xls){
          padding: 15px;
        }

        a {
          display: flex;
          justify-content: center;
          color:$color-black;
          text-decoration: none;
          font-size: 18px;
          padding: 15px;

          @include media-up(lg){
            padding: 0;
            line-height: 1;
            font-size: 16px;
          }

          &:hover {
            opacity: 0.5;
            transition: all .2s ease;
          }
        }
      }
    }
  }

  .header-botton {
    position: fixed;
    bottom:0;
    left:0;
    width: 100%;
    z-index: 1000;

    @include media-up(lg){
      top: 0px;
      bottom: auto;
      left: auto;
      right: 0px;
      width: auto;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-main;
      color: $color-white;
      font-size: 18px;
      text-decoration: none;
      padding: 10px;

      @include media-up(lg){
        width: 170px;
        height: 80px;
        font-size: 18px;
        text-align: center;
      }

      &:hover {
        opacity: 0.75;
        transition: all .2s ease;
      }
    }
  }
}



// menu action

.menu {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 53px;
  width: 35px;
  z-index: 9999;
  span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  @include media-up(lg){
    display: none;
  }
}
.menu .menu-line {
position: absolute;
width: 35px;
left: 0px;
height: 3px;
border-radius: 2px;
background: $color-main;
}
 .menu .menu-line:nth-of-type(1) {
top: 8px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-line:nth-of-type(2) {
top: 16px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-line:nth-of-type(3) {
top: 24px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line {
background: $color-main;
}
 .menu.open .menu-line:nth-of-type(1) {
top: 16px;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line:nth-of-type(2) {
opacity: 0;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line:nth-of-type(3) {
top: 16px;
-webkit-transform: rotate(-45deg);
-ms-transform: rotate(-45deg);
transform: rotate(-45deg);
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-text {
}

 .menu .menu-text span {
  width: 30px;
  color: $color-main;
  position: absolute;
  left: 10px;
  bottom: 4px;
  line-height: 1;
  text-align: center;
}
 .menu .menu-text span img {
  width: 28px !important;
  height: auto;
}
