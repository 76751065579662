#system {
  .system-header {
    background-image: url(./assets/image/system/system-header_sp.png);
    @include media-up(lg){
      background-image: url(./assets/image/system/system-header_pc.png);
    }
  }

  .system-contents {
    margin-top: 60px;

    @include media-up(lg){
      margin-top: 100px;
    }
  }

  .system-contents__title {
    text-align: center;

    h2 {
      font-size: 25px;
    }

    p {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .system-memberprice {
    margin-top: 60px;
    padding: 0 20px;

    .system-memberprice__price {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      border: 2px solid $color-main;
      padding: 20px;

      .strong {
        font-size: 35px;
        color: $color-main;
      }

      p {
        font-size: 16px;
      }
    }
  }

  .system-memberprice__list {
    margin-top: 50px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    @include media-up(lg){
      margin-top: 80px;
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -30px;
    }
  }

  .system-memberprice__item {

    @include media-up(lg){
      width: calc(50% - 30px);
      margin-left: 30px;
      margin-bottom: 50px;
    }
    h3 {
      background-color: #E4F3F0;
      padding: 20px;
      font-size: 20px;
      text-align: center;
    }
    p {
      margin-top: 20px;
      font-size: 16px;
    }

    + .system-memberprice__item {
      margin-top: 50px;

      @include media-up(lg){
        margin-top: 0;
      }
    }

    .price-table {
      max-width: 400px;
      width: 100%;
      margin: 50px auto 0;

      @include media-up(lg){
        max-width: 100%;
        margin: 0;
        margin-top: 50px;
      }

      table {
        width: 100%;
        font-size: 16px;

        + p {
          margin-top: 5px;
          text-align: right;
          font-size: 11px;
          color: $color-main;

          @include media-up(lg){
            font-size: 16px;
          }

        }
      }

      th {
        border-bottom: 1px solid $color-main;
        text-align: center;
        font-size: 12px;
        padding-bottom: 10px;
      }

      td {
        width: 50%;
        border-bottom: 1px solid $color-main;
        font-size: 17px;
        color: $color-main;
        padding: 10px;

        @include media-up(lg){
          font-size: 22px;
          padding: 10px 20px;
        }

        span {
          font-size: 12px;

          @include media-up(lg){
            font-size: 16px;
          }
        }

        + td {
          border-left: 1px solid $color-main;
          font-size: 21px;
          text-align: center;

          @include media-up(lg){
            font-size: 27px;
            text-align: right;
            padding-right: 50px;
            position: relative;

            span {
              position: absolute;
              right: 25px;
            }
          }
        }
      }
    }
  }

  .system-lesson {
    margin-top: 90px;
    background-image: url(./assets/image/system/sp_trialback.png);
    background-size: cover;
    padding: 40px 60px;
    min-height: 300px;

    @include media-up(lg){
      margin-top: 110px;
      background-image: url(./assets/image/system/trialback@2x.png);
      padding: 50px 70px;

    }

    h3 {
      font-size: 25px;
      color: $color-main;
    }

    dl {
      margin-top: 20px;
      display: flex;

      @include media-up(lg){
        width: 50%;
      }

      dt,dd {
        width: 50%;
        font-size: 22px;
        color: $color-main;
      }
    }

    p {
      margin-top: 30px;
      font-size: 16px;
    }
  }

  .system-payment {
    margin-top: 70px;
    padding: 0 20px;

    @include media-up(lg){
      padding: 0;
      margin-top: 100px;
    }

    p {
      font-size: 16px;
      margin-top: 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      li {
        width: 33.333%;
        padding: 10px;
        text-align: center;

        @include media-up(lg){
          width: 16.6666%;
        }

        img {
          width: auto;
          height: 60px;

          @include media-up(lg){
            height: 80px;
          }
        }
      }
    }
  }

  .system-campaign {
    padding: 20px;
    margin-top: 60px;

    @include media-up(lg){
      padding: 0;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }

}
